import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_main = _resolveComponent("ui-main")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ui_header, { "with-back-button": "" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.pvzId ? 'Редактирование ПВЗ' : 'Добавление ПВЗ'), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ui_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.form,
          class: "form",
          "label-width": "160px",
          onSubmit: _cache[12] || (_cache[12] = _withModifiers(() => !!_ctx.pvzId ? _ctx.updatePvz() : _ctx.addPvz(), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: "Город",
              prop: "city"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_autocomplete, {
                  modelValue: _ctx.form.city,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.city) = $event)),
                  "fetch-suggestions": _ctx.allCities
                }, null, 8, ["modelValue", "fetch-suggestions"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Город не в поиске",
              prop: "form.customCity"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  size: "large",
                  modelValue: _ctx.form.customCity,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.customCity) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Полный адрес",
              prop: "points[0].value"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.points[0].value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.points[0].value) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "ФИАС",
              prop: "points[0].id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.points[0].id,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.points[0].id) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Широта",
              prop: "points[0].latitude"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.points[0].latitude,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.points[0].latitude) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Долгота",
              prop: "points[0].longitude"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.points[0].longitude,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.points[0].longitude) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Режим работы",
              prop: "form.points[0].workingTime"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.points[0].workingTime,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.points[0].workingTime) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Доп. информация",
              prop: "form.points[0].additionalField"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: _ctx.form.points[0].additionalField,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.points[0].additionalField) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Отделение почты",
              prop: "form.points[0].isPost"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  size: "large",
                  modelValue: _ctx.form.points[0].isPost,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.points[0].isPost) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              label: "Платная доставка",
              prop: "form.points[0].isPayed"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_switch, {
                  size: "large",
                  modelValue: _ctx.form.points[0].isPayed,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.points[0].isPayed) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Дата открытия" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.form.points[0].dateOpened,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.points[0].dateOpened) = $event)),
                  type: "date",
                  placeholder: "Выберите дату",
                  size: "default"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              onClick: _cache[11] || (_cache[11] = () => !!_ctx.pvzId ? _ctx.updatePvz() : _ctx.addPvz()),
              type: "primary",
              size: "large"
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Подтвердить")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    })
  ]))
}