import 'element-plus/dist/index.css';

import ElementPlus from 'element-plus';
import ru from 'element-plus/es/locale/lang/ru';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { createApp } from 'vue';
import VueClipboard from 'vue3-clipboard'


import App from './App.vue';
import router from './router';
import { store } from './store';
createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: ru })
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .component('QuillEditor', QuillEditor)
  .mount('#app');
