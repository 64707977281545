<template>
  <div class="header" @click="changeCollapse(!collapse)">
    <ui-icon icon="menu" :width="24" :height="24" class="ui-nav-mobile__menu-icon" />
    <ui-icon class="ui-nav-mobile__logo" :icon="iconName" :width="logoWidth" :height="24" />
  </div>
  <div @click="changeCollapse(!collapse)" :class="collapse ? 'collapse-menu' : ''" class=" ui-nav-mobile">
    <div class="ui-nav-mobile__menu">
      <template v-for="menuItem in menuItems" :key="menuItem.url">
        <router-link v-if="isAllow(menuItem.allow)" :to="menuItem.url" class="ui-nav-mobile__menu-item"
          :class="{ active: isActive(menuItem.url) }">
        <div class="el-badge custom-badge" >
          <ui-icon :icon="menuItem.icon" :width="24" :height="24" class="ui-nav-mobile__menu-icon" />
          <sup class="el-badge__content" v-if="menuItem.setBadge && count > 0">{{ count }}</sup>
        </div>
          <span class="ui-nav-mobile__menu-title" :class="{ 'hidden': collapse }">
            {{ menuItem.name }}
          </span>
        </router-link>
      </template>
    </div>

    <el-button type="danger" link class="ui-nav-mobile__logout" @click="handleLogout">
      <span class="ui-nav-mobile__menu-title_logout" :class="{ 'hidden': collapse }">
        Выйти
      </span>
      <ui-icon icon="logout" class="ui-nav-mobile__logout-icon" :width="24" :height="24" />
    </el-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UiIcon from '@/components/ui/ui-icon.vue';
import UiLogo from '@/components/ui/ui-logo.vue';
import { useUserStore } from '@/store/use-user-store';
import { MenuItems } from '@/utils/get-menu-items';
import { getExpiringCount } from '@/api'
import { useMediaQuery } from '@vueuse/core';

export default defineComponent({
  name: 'ui-mobile-nav',
  components: {
    UiLogo,
    UiIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const collapse = ref(true);
    const store = useUserStore();
    const iconName = ref(`${process.env.VUE_APP_THEME}-logo`);
    const logoWidth = ref(200);
    const count = ref(0);
    const mobileSize = useMediaQuery('(max-width: 768px)');
    const changeCollapse = (isActive: boolean) => {
      collapse.value = isActive
    };
    const handleLogout = () => {
      router.push({ name: 'logout' });
    };
    const isAllow = (allow: string[]) => {
      return allow.find((role) => role === store.role);
    }

    const isActive = (path: string) => route.path.startsWith(path);


    onMounted(async () => {
      count.value = userStore.expCount;
    });

    watch(()=> userStore.expCount,()=>{
      count.value = userStore.expCount;
    });

    return {
      menuItems: MenuItems,
      user: userStore.user,
      handleLogout,
      isActive,
      collapse,
      mobileSize,
      changeCollapse,
      iconName,
      logoWidth,
      isAllow,
      count
    };
  },
});
</script>

<style lang="stylus" scoped>
.ui-nav-mobile__menu-icon
  color white

.header
  position: fixed
  top: 0
  padding: 10px 16px
  width: 100%
  height: 50px
  display: flex
  background-color var(--el-color-primary-dark)
  z-index 9999
.ui-nav-mobile
  height 100%
  padding 16px
  position: fixed
  top: 50px
  left: 0
  display flex
  flex-direction column
  background-color var(--el-color-primary-dark)
  font-weight 400
  font-size 16px
  line-height 24px
  z-index 9999
  width: 100%
  transition:  all 0.5s

  &__logo
    width 100%
    animation: myAnim 0.5s;
    // opacity: 1
    // transition:  all 0.5s

  &__menu
    flex-grow 0.92
    display flex
    flex-direction column

  &__menu-item
    padding 12px 0
    display flex
    color rgba(white, 0.64)
    &.active
      color white

  &__menu-title
    margin-left 12px
    width: 155px
    transition:  all 0.5s
    white-space nowrap

  &__menu-title_logout
    margin-right 12px
    width: 44px
    transition:  all 0.5s


  &__logout
    padding 12px 0
    align-items: flex-start
    justify-content flex-start

  .hidden
    transition:  all 0.5s
    width: 0
    opacity 0
    margin-right: 0
    margin-left: 0
    white-space nowrap
  .hidden-logo
    animation: myAnim 0.5s;

.collapse-menu
  transition: all 0.5s
  width: 0
  left: -1000px
@keyframes myAnim {
	0% {
		opacity: 0 !important;
	}

  100% {
		opacity: 1;
	}
}

.custom-badge
  position: relative;
  vertical-align: middle;
  display: inline-block;

  .el-badge__content
    position: absolute
    top: -11px !important;
    right: -14px !important;
    background-color: red
    border-radius: var(--el-badge-radius);
    color: var(--el-color-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: var(--el-badge-size);
    padding: 0 3px;
    white-space: nowrap;
    border: none !important

</style>
