import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__header" }
const _hoisted_3 = { class: "login__main" }
const _hoisted_4 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_logo = _resolveComponent("ui-logo")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_ui_logo, {
        width: "320",
        height: "32"
      })
    ]),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "card__title" }, "Система управления заказами", -1)),
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.form,
          rules: _ctx.formRules,
          class: "form",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.handleSubmit(_ctx.formRef)), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              class: "form__item",
              prop: "login"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event)),
                  size: "large",
                  placeholder: "Логин"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, {
              class: "form__item",
              prop: "password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.form.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event)),
                  type: "password",
                  size: "large",
                  placeholder: "Пароль",
                  "show-password": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { class: "form__submit" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  "native-type": "submit",
                  type: "danger",
                  size: "large",
                  disabled: _ctx.invalid || _ctx.isSubmitting,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSubmit(_ctx.formRef)))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Войти ")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ]))
}