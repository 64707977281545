<template>
  <div>
    <template v-if="text">
      <el-text class="copy-text" v-clipboard:copy="text" v-clipboard:success="onCopy" v-clipboard:error="onError">
        {{ text }}
        <el-icon><copy-document /></el-icon>
      </el-text>
    </template>
    <template v-else>
      <el-text class="only-text">
        не присвоен
      </el-text>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { CopyDocument } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';
export default defineComponent({
  components: {
    CopyDocument,
  },
  props: {
    text: {
      type: String,
    }
  },
  setup() {
    const onCopy = (e) => {
      ElNotification({ position: 'bottom-right', type: 'success', message: 'Код товара скопирован' });
    }
    const onError = (e) => {
      ElNotification({ position: 'bottom-right', type: 'error', message: 'Код товара не скопирован' });
    }

    return { onCopy, onError }
  }
})
</script>

<style lang="stylus" scoped>
.copy-text
  display: flex;
  cursor: pointer;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &:hover
    color: #2C2D84;
.only-text
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
</style>
