import { PaginationParams } from '@/models/pagination-params.type';

export const UPLOAD_PATH = process.env.VUE_APP_UPLOAD_URL;
export const UPLOAD_TOKEN = process.env.VUE_APP_UPLOAD_TOKEN;
export const API_URL = process.env.VUE_APP_API_URL;

export const LOGIN_PATH = process.env.VUE_APP_API_URL + 'authenticate';
export const REFRESH_PATH = process.env.VUE_APP_API_URL + 'refresh';
export const OUTER_LINK = process.env.VUE_APP_OUTER_LINK;
export const CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
export const GRANT_TYPE = process.env.VUE_APP_GRANT_TYPE;
export const ERROR_TEXT = 'Что-то пошло не так';

export const AUTHORIZATION_ERROR_TEXT = 'Не авторизован';
export const FAULT_TOKEN_ERROR_TEXT = 'Токен неисправен';
export const VALIDATION_ERROR_TEXTS = {
  required: 'Обязательное поле',
  number: 'Должно быть числом',
  minPriceKe: 'Сумма доставки должна быть не ниже:',
  wrongId: 'некорректное значения ID продукта',
};

export const DEFAULT_PAGEABLE_LIST = {
  data: [],
  total: 0,
};

export const DEFAULT_PAGE_SIZE = Math.round((window.innerHeight - (80+68+48+50+63+41)) /41 );
export const DEFAULT_PARAMS: PaginationParams = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const PAGINATION_PARAMS_NEW ={
  page: 1,
  size: DEFAULT_PAGE_SIZE,
}
