import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex-box" }
const _hoisted_2 = { class: "package-count" }
const _hoisted_3 = { class: "flex-box" }
const _hoisted_4 = {
  key: 0,
  class: "package-items"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      right: _withCtx(() => [
        _createTextVNode(_toDisplayString(`${_ctx.store.currentPath?.pointAbv}${_ctx.store.currentPath?.partnerPointUid ? ` (${_ctx.store.currentPath?.partnerPointUid})` : ''}`), 1)
      ]),
      default: _withCtx(() => [
        _cache[11] || (_cache[11] = _createTextVNode(" Выдача ПВЗ "))
      ]),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.form,
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.getOrderInfo()), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "orderId" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  ref: "searchInput",
                  "prefix-icon": _ctx.Search,
                  placeholder: "Поиск заказа",
                  style: {"width":"100%","max-width":"100%"},
                  size: "large",
                  class: "search-input",
                  modelValue: _ctx.form.orderId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.orderId) = $event))
                }, null, 8, ["prefix-icon", "modelValue"]),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_el_button, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getOrderInfo())),
                    size: "large",
                    type: "primary",
                    disabled: _ctx.isLoading,
                    loading: _ctx.isLoading,
                    style: 'min-width: 116px'
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" Искать ")
                    ])),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        (_ctx.orderInfo)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, [
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "title" }, "Количество пакетов", -1)),
                _createVNode(_component_el_progress, {
                  type: "circle",
                  percentage: _ctx.percent,
                  "stroke-width": 9,
                  color: "#2B2C84"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.scanned) + " / " + _toDisplayString(_ctx.orderInfo.packageQuantity), 1)
                  ]),
                  _: 1
                }, 8, ["percentage"])
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "title" }, "Выдать", -1)),
              _createVNode(_component_el_form, {
                ref: "formRef",
                model: _ctx.formSubmit,
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.submitPackage()), ["prevent"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, { prop: "orderId" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        ref: "submitInput",
                        autofocus: "",
                        size: "large",
                        placeholder: "Отсканируйте код пакета",
                        class: "search-input",
                        modelValue: _ctx.formSubmit.orderId,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formSubmit.orderId) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_el_button, {
                          size: "large",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submitPackage())),
                          type: "primary",
                          disabled: _ctx.isLoading,
                          loading: _ctx.isLoading,
                          style: 'min-width: 116px'
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Подтвердить ")
                          ])),
                          _: 1
                        }, 8, ["disabled", "loading"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"]),
              _cache[18] || (_cache[18] = _createElementVNode("p", { class: "title" }, "Состав заказа", -1)),
              (_ctx.orderInfo.orderStringsInfo.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderInfo.orderStringsInfo, (product) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: product.prodLink
                      }, [
                        _createElementVNode("a", {
                          href: product.prodLink,
                          target: "__blank"
                        }, _toDisplayString(product.productName), 9, _hoisted_5),
                        _createElementVNode("span", null, _toDisplayString(` x ${product.quantity}`), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_dialog, {
                modelValue: _ctx.dialogFormVisible,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dialogFormVisible) = $event)),
                width: _ctx.dialogWidth,
                title: "Код подтверждения"
              }, {
                footer: _withCtx(() => [
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_el_button, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.dialogFormVisible = false))
                    }, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode("Отмена")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.submitPackage(true)))
                    }, {
                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                        _createTextVNode(" Подтвердить ")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    model: _ctx.formSubmit,
                    onSubmit: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.submitPackage(true)), ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        ref: "codeInput",
                        label: "Код",
                        "label-width": "40px"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            autofocus: "",
                            modelValue: _ctx.formSubmit.pickupCode,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formSubmit.pickupCode) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 512)
                    ]),
                    _: 1
                  }, 8, ["model"])
                ]),
                _: 1
              }, 8, ["modelValue", "width"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.isLoading]
    ])
  ], 64))
}