import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-box" }
const _hoisted_2 = { class: "items-center-column" }
const _hoisted_3 = { class: "package-items" }
const _hoisted_4 = { class: "text-block" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "price-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _component_copy = _resolveComponent("copy")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Статусы ")
      ])),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "formRef",
          model: _ctx.form,
          onSubmit: _withModifiers(_ctx.findById, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { prop: "orderId" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  class: "search-input",
                  modelValue: _ctx.form.orderId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.orderId) = $event)),
                  style: {"width":"100%","max-width":"100%"},
                  clearable: "",
                  placeholder: "Введите номер заказа",
                  size: "large",
                  onClear: _ctx.clearForm
                }, null, 8, ["modelValue", "onClear"]),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_el_button, {
                    onClick: _ctx.findById,
                    type: "primary",
                    size: "large",
                    disabled: _ctx.isLoading,
                    loading: _ctx.isLoading,
                    style: 'min-width: 116px'
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Искать ")
                    ])),
                    _: 1
                  }, 8, ["onClick", "disabled", "loading"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "onSubmit"]),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isSearch)
            ? (_openBlock(), _createBlock(_component_el_table, {
                key: 0,
                data: _ctx.elements.data,
                border: true,
                "empty-text": "Нет данных",
                "table-layout": "auto",
                onSortChange: _ctx.sortChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "orderId",
                    label: "Номер заказа"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "status",
                    label: "Статус"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "statusModtime",
                    label: "Дата статуса"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(row.statusModtime)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "pointAbv",
                    label: "ПВЗ назначения"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "pointAddress",
                    label: "Адрес ПВЗ назначения"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "daysInTransit",
                    label: "Дней в транзите"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "payed",
                    label: "Дата оплаты"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(row.payed)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "onSortChange"]))
            : (_openBlock(), _createBlock(_component_el_table, {
                key: 1,
                data: _ctx.searchElements.data,
                border: true,
                "empty-text": "Нет данных",
                "table-layout": "auto",
                onSortChange: _ctx.sortChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "orderId",
                    label: "Номер заказа"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "status",
                    label: "Статус"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "modtime",
                    label: "Дата статуса"
                  }, {
                    default: _withCtx(({ row }) => [
                      _createTextVNode(_toDisplayString(_ctx.formatDate(row.modtime)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "pointAbv",
                    label: "ПВЗ назначения"
                  }),
                  _createVNode(_component_el_table_column, {
                    sortable: "custom",
                    prop: "pointAddress",
                    label: "Адрес ПВЗ назначения"
                  })
                ]),
                _: 1
              }, 8, ["data", "onSortChange"])),
          (_ctx.elements.total > _ctx.elements.data.length && !_ctx.isSearch)
            ? (_openBlock(), _createBlock(_component_ui_pagination, {
                key: 2,
                total: _ctx.elements.total,
                "current-page": _ctx.page,
                onChange: _ctx.setPage
              }, null, 8, ["total", "current-page", "onChange"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.isLoading]
    ]),
    (_ctx.comments && _ctx.isSearch)
      ? _withDirectives((_openBlock(), _createBlock(_component_ui_main, {
          key: 0,
          style: {"margin-top":"24px"}
        }, {
          default: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("h5", { style: {"margin-bottom":"16px"} }, "Комментарии", -1)),
            _createVNode(_component_el_form, {
              ref: "formRef",
              model: _ctx.form,
              onSubmit: _withModifiers(_ctx.addComment, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form_item, { prop: "orderId" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      type: "textarea",
                      modelValue: _ctx.formComment.comment,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formComment.comment) = $event)),
                      placeholder: "Комментарий..."
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_el_button, {
                      onClick: _ctx.addComment,
                      type: "primary",
                      size: "large",
                      disabled: _ctx.isLoading,
                      loading: _ctx.isLoading,
                      style: 'min-width: 116px; margin-top: 16px; margin-left: 16px'
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Добавить ")
                      ])),
                      _: 1
                    }, 8, ["onClick", "disabled", "loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onSubmit"]),
            _createVNode(_component_el_table, {
              data: _ctx.comments,
              style: {"width":"100%"},
              "table-layout": "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "comment",
                  label: "Комментарий"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "modtime",
                  label: "Дата создания"
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_ctx.formatDate(row.modtime)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"])
          ]),
          _: 1
        })), [
          [_directive_loading, _ctx.loadingComment]
        ])
      : _createCommentVNode("", true),
    (_ctx.products && _ctx.products.length > 0)
      ? (_openBlock(), _createBlock(_component_ui_main, {
          key: 1,
          style: {"margin-top":"24px"}
        }, {
          default: _withCtx(() => [
            _cache[7] || (_cache[7] = _createElementVNode("p", { class: "title" }, "Состав заказа", -1)),
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: product.prodLink
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_copy, {
                      text: product.skuBarcode
                    }, null, 8, ["text"]),
                    _createElementVNode("a", {
                      href: product.prodLink,
                      target: "__blank"
                    }, _toDisplayString(product.productName), 9, _hoisted_5)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("span", null, _toDisplayString(`${product.quantity} шт.`), 1),
                    _cache[6] || (_cache[6] = _createElementVNode("svg", {
                      width: "4",
                      height: "4",
                      viewBox: "0 0 4 4",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }, [
                      _createElementVNode("path", {
                        d: "M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z",
                        fill: "#C2C4CC"
                      })
                    ], -1)),
                    _createElementVNode("span", null, _toDisplayString(`${product.sumPrice}₽`), 1)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}