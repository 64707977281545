<template>
  <ui-header>
    Выдача ПВЗ
    <template #right>{{`${store.currentPath?.pointAbv}${store.currentPath?.partnerPointUid ? ` (${store.currentPath?.partnerPointUid})` : ''}` }}</template>
  </ui-header>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="form" @submit.prevent="getOrderInfo()">
      <el-form-item prop="orderId">
        <el-input ref="searchInput" :prefix-icon="Search" placeholder="Поиск заказа"
          style="width: 100%; max-width: 100%;" size="large" class="search-input" v-model="form.orderId" />
        <div class="flex-box">
          <el-button @click="getOrderInfo()" size="large" type="primary" :disabled="isLoading" :loading="isLoading"
            :style="'min-width: 116px'">
            Искать
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <template v-if="orderInfo">

      <div class="package-count">
        <p class="title">Количество пакетов</p>
        <el-progress type="circle" :percentage="percent" :stroke-width="9" color="#2B2C84">
          <span>{{ scanned }} / {{ orderInfo.packageQuantity }} </span>
        </el-progress>
      </div>

      <div class="title">Выдать</div>
      <el-form ref="formRef" :model="formSubmit" @submit.prevent="submitPackage()">
        <el-form-item prop="orderId">
          <el-input ref="submitInput" autofocus size="large" placeholder="Отсканируйте код пакета" class="search-input"
            v-model="formSubmit.orderId" />
          <div class="flex-box">
            <el-button size="large" @click="submitPackage()" type="primary" :disabled="isLoading" :loading="isLoading"
              :style="'min-width: 116px'">
              Подтвердить
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <!-- <el-scrollbar style="margin-bottom: 16px; margin-top: 14px">
        <el-descriptions title="Получатель" column="1" :border="true" size="16">
          <el-descriptions-item label="ФИО:">{{ receiver }}</el-descriptions-item>
          <el-descriptions-item label="Телефон">{{ orderInfo.recPhone }}</el-descriptions-item>
          <el-descriptions-item label="Email">{{ orderInfo.recEmail }}</el-descriptions-item>
        </el-descriptions>
      </el-scrollbar> -->
      <!-- <el-scrollbar style="margin-bottom: 32px">
        <el-descriptions title="Покупатель" column="1" :border="true" size="16">
          <el-descriptions-item label="ФИО:">{{ customer }}</el-descriptions-item>
          <el-descriptions-item v-if="orderInfo.custPhone" label="Телефон">{{ orderInfo.custPhone
          }}</el-descriptions-item>
          <el-descriptions-item v-if="orderInfo.custEmail" label="Email">{{ orderInfo.custEmail }}</el-descriptions-item>
        </el-descriptions>
      </el-scrollbar> -->

      <p class="title">Состав заказа</p>
      <ul v-if="orderInfo.orderStringsInfo.length > 0" class="package-items">
        <li v-for="product in orderInfo.orderStringsInfo" :key="product.prodLink">
          <a :href="product.prodLink" target="__blank">
            {{ product.productName }}
          </a>
          <span>
            {{ ` x ${product.quantity}` }}
          </span>
        </li>
      </ul>

      <el-dialog v-model="dialogFormVisible" :width="dialogWidth" title="Код подтверждения">
        <el-form :model="formSubmit" @submit.prevent="submitPackage(true)">
          <el-form-item ref="codeInput" label="Код" label-width="40px">
            <el-input autofocus v-model="formSubmit.pickupCode" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">Отмена</el-button>
            <el-button type="primary" @click="submitPackage(true)">
              Подтвердить
            </el-button>
          </span>
        </template>
      </el-dialog>

    </template>

  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, getCurrentInstance } from 'vue';

import { getOrderById, confirmPickup } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { dateFormat } from '@/utils/date-format';
import { OrderPickupInfo } from '@/models/api/order-pickup-dto.interface';
import { useMediaQuery } from '@vueuse/core';
import { useUserStore } from '@/store/use-user-store';
import { Search } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  name: 'order-pickup',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
  },
  setup() {
    const form = ref({
      orderId: '',
    });

    const dialogFormVisible = ref(false)

    const formSubmit = ref({
      orderId: '',
      pickupCode: '',
    });
    // const searchInput = ref(null);
    // const submitInput = ref(null);
    const secondScan = ref(false);

    const isLoading = ref(false);
    const orderInfo = ref<OrderPickupInfo | null>(null);
    const scanned = ref(0);
    const mobileSize = useMediaQuery('(max-width: 768px)');
    const instance = getCurrentInstance();
    const timer = ref<any>(null);

    const dialogWidth = computed(() => {
      return mobileSize.value ? '100%' : '50%'
    })

    const percent = computed(() => {
      if (orderInfo.value) {
        if (orderInfo.value.sumPackageQuantity - orderInfo.value.packageQuantity > 0) {
          scanned.value = orderInfo.value.sumPackageQuantity - orderInfo.value.packageQuantity
          orderInfo.value.packageQuantity = orderInfo.value.sumPackageQuantity;
          return Math.round((scanned.value / orderInfo.value.packageQuantity) * 100)
        }
        return Math.round((scanned.value / orderInfo.value.packageQuantity) * 100)
      }
      else return 0
    })



    const getOrderInfo = async () => {
      if (scanned.value > 0) {
        ElMessageBox.alert(`Вы выдали только ${scanned.value} из ${orderInfo.value?.packageQuantity}. Прежде чем искать новый заказ выдайте все пакеты `, 'Выданы не все пакеты. ', {
          type: 'warning',
          center: true,
          confirmButtonText: 'Хорошо',
          callback: () => {
            return
          },
        })
        return
      }
      isLoading.value = true;
      await getOrderById(form.value.orderId).then((data) => {
        if (typeof data === 'string') {
          ElMessageBox.alert(data, 'Заказ выдан', {
            type: 'warning',
            center: true,
            confirmButtonText: 'Хорошо',
            callback: () => {
              return
            },
          })
        } else {
          orderInfo.value = data;
          if(orderInfo.value.sumPackageQuantity - orderInfo.value.packageQuantity > 0){
            secondScan.value = true;
          }

        }
        isLoading.value = false;
      }).finally(() => isLoading.value = false)
    }

    const receiver = computed(() => {
      if (orderInfo.value) {
        return `${orderInfo.value.recSname} ${orderInfo.value.recFname}`
      } else
        return ''
    })

    const customer = computed(() => {
      if (orderInfo.value) {
        return `${orderInfo.value.custSname} ${orderInfo.value.custFname}`
      } else
        return ''
    })

    const formatDate = (date: string) => dateFormat(date);

    const extractId = (str: string) => {
      const match = str.substring(5);

      if (match) {
        return match.split('-')[0];
      }
      return ''
    };

    const store = useUserStore()

    const submitPackage = async (fromPopup: boolean = false) => {
      const orderId = formSubmit.value.orderId;
      if (!fromPopup) {
        if (scanned.value < 1 || secondScan.value) {
          secondScan.value = false;
          dialogFormVisible.value = true;
        } else if (orderInfo.value && scanned.value + 1 == orderInfo.value.packageQuantity) {
          await confirmPickup(orderId, formSubmit.value.pickupCode).then((data) => {
            dialogFormVisible.value = false;
            scanned.value++;

            ElMessageBox.alert(data, 'Заказ выдан', {
              type: 'success',
              center: true,
              confirmButtonText: 'Хорошо',
              callback: () => {
                dialogFormVisible.value = false;
                form.value.orderId = '';
                orderInfo.value = null;
                formSubmit.value = {
                  orderId: '',
                  pickupCode: '',
                };
                scanned.value = 0;
                store.getExpCount();
              },
            })
            // ElNotification({ position: 'bottom-right', type: 'success', message: 'Заказ выдан успешно!' });
          })
          return
        } else {
          await confirmPickup(orderId, formSubmit.value.pickupCode).then(() => {
            scanned.value++
          })
        }
      } else {
        if (orderInfo.value && scanned.value + 1 == orderInfo.value.packageQuantity) {
          await confirmPickup(orderId, formSubmit.value.pickupCode).then((data) => {
            dialogFormVisible.value = false;
            scanned.value++;

            ElMessageBox.alert(data, 'Заказ выдан', {
              type: 'success',
              center: true,
              confirmButtonText: 'OK',
              callback: () => {
                dialogFormVisible.value = false;
                form.value.orderId = '';
                orderInfo.value = null;
                formSubmit.value = {
                  orderId: '',
                  pickupCode: '',
                };
                scanned.value = 0;
                store.getExpCount();
              },
            })
            // ElNotification({ position: 'bottom-right', type: 'success', message: 'Заказ выдан успешно!' });
          })
          return
        }
        await confirmPickup(orderId, formSubmit.value.pickupCode).then((data) => {
          dialogFormVisible.value = false;
          scanned.value++;
        })
      }
    }

    const onInput = () => {
      clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        submitPackage();
      }, 2000);
    };


    return {
      formatDate,
      form,
      submitPackage,
      isLoading,
      getOrderInfo,
      orderInfo,
      receiver,
      customer,
      formSubmit,
      scanned,
      dialogFormVisible,
      dialogWidth,
      percent,
      store,
      onInput,
      instance,
      Search,
      ElMessage,
      ElMessageBox,
    };
  },
});
</script>

<style lang="stylus" scoped>
.title
  color: var(--el-text-color-primary)
  font-size: 16px
  font-weight: 700
  margin-bottom: 16px;
  width: 100%;
.package-count
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 14px
  @media (max-width 768px)
    flex-direction: column
    justify-content: center

.package-items
  li
    font-size: 16px
    line-height: 24px
    margin-bottom: 12px
    display: flex;
    justify-content: space-between
    max-width: 800px
    gap: 16px
    @media (max-width 768px)
      max-width 100%
      width: 100%
      align-items: center
    a
      color: var(--el-color-primary-dark-2)
      text-decoration: underline
      // border-bottom: 1px solid currentColor
    span
      font-weight 500
      white-space: nowrap
</style>
