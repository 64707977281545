<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { initiateAxios } from '@/api/initiate-axios';
import { useUserStore } from '@/store/use-user-store';

export default defineComponent({
  name: 'app',
  setup() {
    const router = useRouter();
    const store = useUserStore();
    onMounted(async () => {
      const checkUser = await store.getLocalUser();
      if (!checkUser) {
        router.push({ name: 'logout' });
      }
    })


    initiateAxios(router);
  },
});
</script>

<style lang="stylus">
@import '@/styles/reset.styl'
@import '@/styles/main.styl'
@import '@/styles/themes/psb/variables.styl'

html, body, #app
  height 100%
</style>
