import { PickupLocationDto } from "@/models/api/order-pickup-dto.interface";
import { OrderPickupInfo } from "@/models/api/order-pickup-dto.interface";
import { Pageable } from "@/models/pageable.type";
import { PaginationParams } from "@/models/pagination-params.type";
import { API_URL } from "@/utils/constants";
import axios from "axios";
import { useUserStore } from "@/store/use-user-store";

export const updateOrderStatus = async (orderId : string): Promise<string> => {
  const store = useUserStore()
  return axios.post(API_URL + `order-pickup-arrival/${store.pvz_id}/update-status`, null, {
    params: {
      orderId,
    }
  }).then((response) =>response.data)
};


export const getReceivedOrders = async (
  params: PaginationParams,
): Promise<Pageable<PickupLocationDto>> => {
  const store = useUserStore()
  const response = await axios
    .get(API_URL + `order-pickup-arrival/${store.pvz_id}`, { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};


export const getPickupLocation = async (
  params: PaginationParams,
): Promise<Pageable<PickupLocationDto>> => {
  const store = useUserStore()
  const response = await axios
    .get(API_URL + `order-pickup-location/${store.pvz_id}`, { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};

//pickup
export const getOrderById = async (orderId: string): Promise<OrderPickupInfo | string> => {
  const store = useUserStore();
  const response  = await axios.get(API_URL + `order-pickup/${store.pvz_id}/${orderId}`)
  return response.data
}


export const confirmPickup = async (orderId : string,  pickupCode: string): Promise<string> => {
  const store = useUserStore();
  return axios.post(API_URL + `order-pickup/${store.pvz_id}/confirm-pickup`, null, {
    params: {
      orderId,
      pickupCode
    }
  }).then((response) =>response.data)
};
