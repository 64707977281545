<template>
  <div class="login">
    <header class="login__header">
      <ui-logo width="320" height="32" />
    </header>
    <main class="login__main">
      <div class="card">
        <h1 class="card__title">Система управления заказами</h1>
        <el-form ref="formRef" :model="form" :rules="formRules" class="form" @submit.prevent="handleSubmit(formRef)">
          <el-form-item class="form__item" prop="login">
            <el-input
              v-model="form.username"
              size="large"
              placeholder="Логин"
            />
          </el-form-item>
          <el-form-item class="form__item" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              size="large"
              placeholder="Пароль"
              show-password
            />
          </el-form-item>
          <el-form-item class="form__submit">
            <el-button
              native-type="submit"
              type="danger"
              size="large"
              :disabled="invalid || isSubmitting"
              @click="handleSubmit(formRef)"
            >
              Войти
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </main>
  </div>
</template>

<script lang='ts'>
import { ElNotification } from 'element-plus';
import { FormInstance, FormItemRule, FormRules } from 'element-plus';
import { computed, defineComponent, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import UiLogo from '@/components/ui/ui-logo.vue';
import { LoginDto } from '@/models/api/login-dto.interface';
import { useUserStore } from '@/store/use-user-store';
import { ERROR_TEXT, VALIDATION_ERROR_TEXTS } from '@/utils/constants';

const rules: Partial<Record<keyof LoginDto, FormItemRule>> = {
  username: {
    required: true,
    message: VALIDATION_ERROR_TEXTS.required,
  },
  password: {
    required: true,
    message: VALIDATION_ERROR_TEXTS.required,
  },
};

export default defineComponent({
  name: 'login',
  components: {
    UiLogo,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const isSubmitting = ref(false);
    const formRef = ref<FormInstance>();
    const formRules = reactive<FormRules>(rules);

    const form = ref<LoginDto>({
      username: '',
      password: '',
    });

    const invalid = computed(() => !form.value.username || !form.value.password);

    const handleSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((isValid) => {
        if (isValid) {
          isSubmitting.value = true;

          userStore
            .authorize({
              ...form.value,
            })
            .then(() => {
                setTimeout(()=> router.push('/'), 500)
            })
            .catch((error) => {
              const message =
                error.response?.status === 406
                  ? 'Неверный логин или пароль'
                  : ERROR_TEXT;

              ElNotification({position: 'bottom-right', type: 'error', message});
            })
            .finally(async () => {
              isSubmitting.value = false;
               await userStore.getRole().then(async ()=>{
                await userStore.getAllowPaths();
               });
            });
        }
      });
    };

    return {
      formRef,
      form,
      formRules,
      invalid,
      isSubmitting,
      handleSubmit,
    };
  },
});
</script>

<style lang="stylus" scoped>
.login
  height 100%
  display flex
  flex-direction column
  align-items center
  background-color var(--el-color-primary-dark)

  &__header
    padding 24px
    width 100%

  &__main
    flex-grow 1
    width 100%
    display flex
    flex-direction column
    align-items center
    justify-content center

.card
  padding 32px 24px 24px
  max-width 480px
  width 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  background-color white
  border-radius 4px

  &__title
    h4()
    margin-bottom 40px

.form
  width 100%

  &__item
    margin-bottom 32px
    width 100%

  &__submit
    margin-top 40px
    width 100%

    button
      width 100%

  :deep(.el-form-item__error)
    padding-top 8px
</style>
