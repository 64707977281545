import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "items-center-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      right: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _ctx.resetAll,
          type: "primary",
          size: "large"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Сбросить Все")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createTextVNode(" Ошибки Выдачи "))
      ]),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_table, {
            data: _ctx.elements.data,
            border: true,
            "empty-text": "Нет данных",
            "table-layout": "auto",
            onSortChange: _ctx.sortChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "pointId",
                label: "ПВЗ"
              }, {
                default: _withCtx(({ row }) => [
                  _createTextVNode(_toDisplayString(_ctx.pvzAbvById(row.pointId)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "orderId",
                label: "Номер Заказа"
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "errorCount",
                label: "Количество попыток"
              }),
              _createVNode(_component_el_table_column, {
                sortable: "custom",
                prop: "reset",
                label: "Сбросить"
              }, {
                default: _withCtx(({ row }) => [
                  _createVNode(_component_ui_icon, {
                    onClick: ($event: any) => (_ctx.resetCurrent(row.pointId, row.orderId)),
                    class: "cursor-pointer",
                    icon: "refresh",
                    size: 24
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data", "onSortChange"]),
          (_ctx.elements.total > _ctx.elements.data.length)
            ? (_openBlock(), _createBlock(_component_ui_pagination, {
                key: 0,
                total: _ctx.elements.total,
                "current-page": _ctx.page,
                onChange: _ctx.setPage
              }, null, 8, ["total", "current-page", "onChange"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.isLoading]
    ])
  ], 64))
}