import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import Default from '@/layouts/default.vue';
import { useUserStore } from '@/store/use-user-store';
import NotFound from '@/views/404.vue';
import Login from '@/views/login/index.vue';
import RoutingCenter from '@/views/routing-center/index.vue';
import Statuses from '@/views/statuses/index.vue';
import DistributionPickUp from '@/views/distribution-pick-up/index.vue';
import UserControl from '@/views/users-control/index.vue';
import PickupLocation from '@/views/pickup-location/index.vue';
import PickupArrival from '@/views/pickup-arrival/index.vue';
import OrderPickup from '@/views/order-pickup/index.vue';
import ErrorCount from '@/views/error-count/index.vue';
import PickPoints from '@/views/pickpoints/index.vue';
import PvzForm from '@/views/pvz-form/index.vue';
import SortingCenter from '@/views/sorting-center/index.vue';
import Expiring from '@/views/expiring/index.vue';

import { Roles } from '@/models/roles.enum';
const allRoles = Object.values(Roles)
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      needAuth: false,
    }
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: () => {
      const userStore = useUserStore();

      userStore.logout();

      return { name: 'login' };
    },
    meta: {
      needAuth: false,
    }
  },
  {
    path: '/',
    component: Default,
    meta: {
      authorize: allRoles,
      needAuth: true,
    },
    redirect: () => {
      const userStore = useUserStore();
      switch (userStore.role) {
        case Roles.admin:
        case Roles.ccAgent:
          return { name: 'statuses' };
        case Roles.dcManager:
          return { name: 'routing-center'};
        case Roles.sorterDC:
          return { name: 'distribution-pick-up' };
        case Roles.pickpointAdmin:
          return { name: 'pickup-location' };
        case Roles.userSC:
          return {name: 'sort-center'};
        case Roles.pickpointListEditor:
          return { name: 'pick-points' };
        default:
          return { name: 'login' };
      }
    },
    children: [
      {
        path: '/statuses',
        name: 'statuses',
        component: Statuses,
        meta: {
          authorize: [Roles.admin, Roles.ccAgent, Roles.pickpointAdmin],
          needAuth: true,
        }
      },
      {
        path: '/routing-center',
        name: 'routing-center',
        component: RoutingCenter,
        meta: {
          authorize: [Roles.admin, Roles.dcManager],
          needAuth: true,
        },
      },
      {
        path: '/distribution-pick-up',
        name: 'distribution-pick-up',
        component: DistributionPickUp,
        meta: {
          authorize: [Roles.admin, Roles.dcManager, Roles.sorterDC],
          needAuth: true,
        },
      },
      {
        path: '/sort-center',
        name: 'sort-center',
        component: SortingCenter,
        meta: {
          authorize: [Roles.admin,Roles.userSC],
          needAuth: true,
        },
      },
      {
        path: '/user-control',
        name: 'user-control',
        component: UserControl,
        meta: {
          authorize: [Roles.admin],
          needAuth: true,
        },
      },
      {
        path: '/pickup-location',
        name: 'pickup-location',
        component: PickupLocation,
        meta: {
          authorize: [Roles.admin, Roles.pickpointAdmin],
          needAuth: true,
        },
      },
      {
        path: '/pickup-arrival',
        name: 'pickup-arrival',
        component: PickupArrival,
        meta: {
          authorize: [Roles.admin, Roles.pickpointAdmin],
          needAuth: true,
        },
      },
      {
        path: '/order-pickup',
        name: 'order-pickup',
        component: OrderPickup,
        meta: {
          authorize: [Roles.admin, Roles.pickpointAdmin],
          needAuth: true,
        },
      },
      {
        path: '/error-count',
        name: 'error-count',
        component: ErrorCount,
        meta: {
          authorize: [Roles.admin],
          needAuth: true,
        },
      },
      {
        path: '/expiring',
        name: 'expiring',
        component: Expiring,
        meta: {
          authorize: [Roles.admin, Roles.pickpointAdmin],
          needAuth: true,
        },
      },
      {
        path: '/pick-points',
        name: 'pick-points',
        component: PickPoints,
        meta: {
          authorize: [Roles.admin, Roles.pickpointListEditor],
          needAuth: true,
        },
      },
      {
        path: '/pick-points/edit/:city/:id',
        name: 'pvz-edit',
        component: PvzForm,
        props: (route) => ({city: route.params.city ,pvzId: route.params.id }),
        meta: {
          authorize: [Roles.admin, Roles.pickpointListEditor],
          needAuth: true,
        }
      },
      {
        path: '/pick-points/create',
        name: 'pvz-create',
        component: PvzForm,
        meta: {
          authorize: [Roles.admin, Roles.pickpointListEditor],
          needAuth: true,
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      needAuth: false,
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const user = useUserStore()
  // await user.getLocalUser().then((data) => {
  //   if (to.meta.needAuth) {
  //     if (!(!!to.meta.authorize && user && user.role && (to.meta.authorize as Roles[]).findIndex((role) => user.role === role) > -1)) {
  //       return next('/logout');
  //     }
  //   }
  // })

  return next();
});

export default router;


// "Pickpoint Admin" -/order-pickup-location  + /order-pickup-arrival + /order-pickup и всё что с ними связанно но Только для своего ПВЗ и с момента как ему присвоят ПВЗ в /user-management (доступен только суперадмину)
// "admin" - супер админ (доступ ко всему)
//  "Sorter DC" - /distribution-center
// "DC Manager" /distribution-center /routing-center {
//   testdcmanager
//   password
// }
// CC Agent /statuses
