import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"18px"} }
const _hoisted_2 = { style: {"font-size":"18px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_icon, {
      class: "cursor-pointer",
      style: {"outline":"none"},
      icon: 'phone',
      onClick: _ctx.showPhone
    }, null, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
      title: "Контакты",
      "append-to-body": "",
      class: "custom-dialog"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("strong", { style: {"font-weight":"500","font-size":"20px"} }, "Номер: ", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.contacts.recPhone), 1)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("strong", { style: {"font-weight":"500","font-size":"20px"} }, "Email: ", -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.contacts.recEmail), 1)
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_el_button, {
          onClick: _ctx.closeDialog,
          style: 'min-width: 116px; margin-top: 16px;',
          size: "large",
          type: "primary"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Закрыть")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}