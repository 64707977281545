import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-nav-mobile__menu" }
const _hoisted_2 = { class: "el-badge custom-badge" }
const _hoisted_3 = {
  key: 0,
  class: "el-badge__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeCollapse(!_ctx.collapse)))
    }, [
      _createVNode(_component_ui_icon, {
        icon: "menu",
        width: 24,
        height: 24,
        class: "ui-nav-mobile__menu-icon"
      }),
      _createVNode(_component_ui_icon, {
        class: "ui-nav-mobile__logo",
        icon: _ctx.iconName,
        width: _ctx.logoWidth,
        height: 24
      }, null, 8, ["icon", "width"])
    ]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeCollapse(!_ctx.collapse))),
      class: _normalizeClass([_ctx.collapse ? 'collapse-menu' : '', "ui-nav-mobile"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: menuItem.url
          }, [
            (_ctx.isAllow(menuItem.allow))
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: menuItem.url,
                  class: _normalizeClass(["ui-nav-mobile__menu-item", { active: _ctx.isActive(menuItem.url) }])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ui_icon, {
                        icon: menuItem.icon,
                        width: 24,
                        height: 24,
                        class: "ui-nav-mobile__menu-icon"
                      }, null, 8, ["icon"]),
                      (menuItem.setBadge && _ctx.count > 0)
                        ? (_openBlock(), _createElementBlock("sup", _hoisted_3, _toDisplayString(_ctx.count), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["ui-nav-mobile__menu-title", { 'hidden': _ctx.collapse }])
                    }, _toDisplayString(menuItem.name), 3)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _createVNode(_component_el_button, {
        type: "danger",
        link: "",
        class: "ui-nav-mobile__logout",
        onClick: _ctx.handleLogout
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(["ui-nav-mobile__menu-title_logout", { 'hidden': _ctx.collapse }])
          }, " Выйти ", 2),
          _createVNode(_component_ui_icon, {
            icon: "logout",
            class: "ui-nav-mobile__logout-icon",
            width: 24,
            height: 24
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2)
  ], 64))
}