<template>
  <ui-header>Управление пользователями</ui-header>

  <ui-main v-loading="isLoading">
    <el-form label-position="top" class="form" @submit.prevent="showPoint">

      <el-form-item>
        <el-input style="width: 100%; max-width: 100%;" clearable placeholder="Никнейм пользователя" size="large" class="search-input" v-model="form.username" >
        </el-input>
        <el-button type="primary" size="large" :style="'min-width: 116px'" @click="showPoint">
          {{ 'Узнать ПВЗ' }}
        </el-button>
      </el-form-item>

      <el-form-item v-if="showPointForm" label="Текущий ПВЗ">
        <el-select size="large" v-model="pointName" filterable>
          <el-option @click="changePoint(point)" v-for="point in store.allowPaths" :key="point.pointId"
            :label="`${ point.isSorting === 0 ? point.pointAbv : `${point.pointAbv } (сортировочный центр)`}`" :value="point.pointAbv" />
        </el-select>
      </el-form-item>

      <el-form-item v-if="showPointForm">
        <el-button type="primary" size="large" :style="'min-width: 116px'" @click="handleSubmit()">
          {{ 'Изменить' }}
        </el-button>
      </el-form-item>
    </el-form>
  </ui-main>
</template>

<script lang="ts">
import { ElNotification, FormInstance } from 'element-plus';
import {
  computed,
  defineComponent,
  ref,
} from 'vue';

import { assignPoint, getPoints, updatePoint } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import { useUserStore } from '@/store/use-user-store';
import { AllowPathsDto } from '@/models/api/auth-dto.interface';


interface FormControl {
  username: string;
  point: AllowPathsDto;
}

export default defineComponent({
  name: 'user-control',

  components: {
    UiMain,
    UiHeader,
  },

  setup() {
    const isLoading = ref(false);
    const store = useUserStore();
    const showPointForm = ref(false);
    const currentPoint = ref<AllowPathsDto | null | undefined>(null);

    const formRef = ref<FormInstance>();
    const pointName = ref()
    const form = ref<FormControl>({
      username: '',
      point: {
        pointAbv: '',
        pointAddress: '',
        pointId: null,
      },
    });

    const handleSubmit = () => {
      setPoint();
    };
    const changePoint = (point: AllowPathsDto) => {
      form.value.point = point;
    }
    const showPoint = async () => {
      await getPoints(form.value.username).then((data) => {
        if (data) {
          pointName.value = data.pointAbv;
          form.value.point = data
          currentPoint.value = data
        }
        showPointForm.value = true;
      })
    }

    const setPoint = async () => {
      isLoading.value = true;
      try {
        if (currentPoint.value) {
          await updatePoint(form.value.username, currentPoint.value.pointId, form.value.point.pointId).then(data => ElNotification({ position: 'bottom-right', type: 'success', message: data }))
        } else {
          await assignPoint(form.value.username, form.value.point.pointId).then(data => ElNotification({ position: 'bottom-right', type: 'success', message: data }))
        }
      } finally {
        store.getRole()
        store.getAllowPaths(),
        isLoading.value = false;
        showPointForm.value = false;
      }

    }



    return {
      store,
      formRef,
      form,
      handleSubmit,
      isLoading,
      showPoint,
      currentPoint,
      pointName,
      showPointForm,
      setPoint,
      changePoint
    };
  },
});
</script>

<style lang="stylus" scoped>
.form
  max-width 800px
  display flex
  flex-direction column
  gap 20px
</style>
