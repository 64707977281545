import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error" }
const _hoisted_2 = { class: "error__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "error__title" }, "Страница не найдена", -1)),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "error__text" }, " Возможно, эта страница больше не существует или переехала в другой раздел. ", -1)),
    _createElementVNode("p", _hoisted_2, [
      _cache[1] || (_cache[1] = _createTextVNode(" Вы можете перейти на ")),
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("главную страницу.")
        ])),
        _: 1
      })
    ])
  ]))
}