import { MenuIcon } from '@/models/menu-icon.enum';
import { IMenuItem } from '@/models/menu-item.interface';
import { Roles } from '@/models/roles.enum';

export const MenuItems: IMenuItem[] = [
  {
    url: '/statuses',
    name: 'Статусы',
    icon: MenuIcon.Statuses,
    allow: [Roles.admin, Roles.ccAgent, Roles.pickpointAdmin]
  },
  {
    url: '/routing-center',
    name: 'Маршрутизация РЦ',
    icon: MenuIcon.RoutingCenter,
    allow: [Roles.admin, Roles.dcManager]
  },
  {
    url: '/distribution-pick-up',
    name: 'Приемка РЦ',
    icon: MenuIcon.DistributionPickUp,
    allow: [Roles.admin, Roles.dcManager]
  },
  {
    url: '/sort-center',
    name: 'Приемка СЦ',
    icon: MenuIcon.DistributionPickUp,
    allow: [Roles.admin, Roles.userSC]
  },
  {
    url: '/pickup-location',
    name: 'В пути в ПВЗ',
    icon: MenuIcon.PickupLocation,
    allow: [Roles.admin, Roles.pickpointAdmin]
  },
  {
    url: '/pickup-arrival',
    name: 'Приемка ПВЗ',
    icon: MenuIcon.PickupArrival,
    allow: [Roles.admin, Roles.pickpointAdmin]
  },
  {
    url: '/order-pickup',
    name: 'Выдача',
    icon: MenuIcon.OrderPickup,
    allow: [Roles.admin, Roles.pickpointAdmin]
  },
  {
    url: '/user-control',
    name: 'Пользователи',
    icon: MenuIcon.UserControl,
    allow: [Roles.admin]
  },
  {
    url: '/error-count',
    name: 'Ошибки Выдачи',
    icon: MenuIcon.ErrorCount,
    allow: [Roles.admin]
  },
  {
    url: '/expiring',
    name: 'Истекающие',
    icon: MenuIcon.ErrorCount,
    allow: [Roles.admin, Roles.pickpointAdmin],
    setBadge: true,
  },
  {
    url: '/pick-points',
    name: 'ПВЗ',
    icon: MenuIcon.PickupLocation,
    allow: [Roles.pickpointListEditor, Roles.admin]
  },
];
