<template>
  <ui-header>
    Маршрутизация распределительного центра
  </ui-header>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="searchForm" label-position="top" @submit.prevent="searchOrder()">
      <el-form-item :prefix-icon="Search" style="margin-bottom: 16px;" prop="orderId">
        <el-input size="large" placeholder="Поиск заказа" :prefix-icon="Search" class="search-input" clearable
          v-model="searchForm.orderId" @input="searchOrder()" @clear="clearForm()" />
      </el-form-item>
    </el-form>
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto" @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер заказа" />
        <el-table-column sortable="custom"  prop="created" label="Дата создания">
          <template #default="{ row }">
            {{ formatDate(row.created) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="statusName" label="Статус" />
        <el-table-column sortable="custom"  prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="pointAddress" label="Адрес ПВЗ" />
        <el-table-column sortable="custom" prop="pointAbv" label="Шифр ПВЗ">
          <template #default="{ row }">
            <order-point @change-value="changePickpoint" :orderId="row.orderId" :pointAbv="row.pointAbv"></order-point>
          </template>
        </el-table-column>
        <el-table-column label="Телефон" prop="more">
          <template #default="{ row }">
            <show-phone :orderId="row.orderId"></show-phone>
          </template>
        </el-table-column>
        </el-table>
        <ui-pagination v-if="elements.total > elements.data.length && !isSearch" :total="elements.total" :current-page="page"
          @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { Delete, Edit } from '@element-plus/icons-vue';
import { defineComponent, ref, watch } from 'vue';

import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { dateFormat } from '@/utils/date-format';
import { getRoutingCenter } from '@/api/distribution-center';
import { RoutingCenterDto } from '@/models/api/routing-center-dto.interface';
import UiIcon from '@/components/ui/ui-icon.vue';
import ShowPhone from './show-phone.vue';
import OrderPoint from './order-point.vue';
import { DEFAULT_PARAMS } from '@/utils/constants';
import { ElNotification } from 'element-plus';
import { Search } from '@element-plus/icons-vue';
export default defineComponent({
  name: 'routing-center',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
    UiIcon,
    ShowPhone,
    OrderPoint
  },
  setup() {
    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<RoutingCenterDto>(getRoutingCenter);
    const formatDate = (date: string) => dateFormat(date);

    const searchForm = ref({
      orderId: ''
    });

    const timer = ref<any>(null);
    const isSearch = ref(false)

    const searchOrder = () => {
      clearTimeout(timer.value);
      isSearch.value = true;
      timer.value = setTimeout(() => {
        getElements({
          orderIdFilter: searchForm.value.orderId,
          ...DEFAULT_PARAMS
        })
      }, 500);
    }

    const clearForm = () => {
      isSearch.value = false
      getElements();
      searchForm.value.orderId = ''
    }


    watch(()=> searchForm.value, ()=> {
      if (searchForm.value.orderId.length === 0){
        clearForm()
      }
    })

    const changePickpoint = () =>{
      getElements()
      ElNotification({ position: 'bottom-right', type: 'success', message: 'ПВЗ изменен!' });
    }
    return {
      Edit,
      Delete,
      isLoading,
      elements,
      page,
      setPage,
      formatDate,
      searchForm,
      getElements,
      searchOrder,
      sortChange,
      changePickpoint,
      clearForm,
      isSearch,
      Search
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
