import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"margin":"16px 0"} }
const _hoisted_2 = { class: "items-center-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      right: _withCtx(() => [
        _createVNode(_component_router_link, { to: "/pick-points/create" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              size: "large"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Добавить ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createTextVNode(" Управление ПВЗ "))
      ]),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: city }, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(city.city), 1),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_table, {
                data: city.points,
                border: true,
                "empty-text": "Нет данных",
                "table-layout": "auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    width: "100",
                    label: "Открыт",
                    style: {"font-weight":"500"}
                  }, {
                    default: _withCtx(({ row }) => [
                      _createElementVNode("span", {
                        style: _normalizeStyle([{"font-weight":"500","font-size":"16px"}, _ctx.isSameOrBefore(row.dateOpened) ? {color:'green'} : {color: 'red'}])
                      }, _toDisplayString(_ctx.isSameOrBefore(row.dateOpened)), 5)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "350px",
                    prop: "id",
                    label: "ФИАС"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "value",
                    label: "Адрес"
                  }),
                  _createVNode(_component_el_table_column, { width: "56" }, {
                    default: _withCtx(({ row }) => [
                      _createVNode(_component_el_tooltip, { content: "Редактировать" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: `/pick-points/edit/${city.city}/${row.id}`
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_button, {
                                icon: _ctx.Edit,
                                type: "primary",
                                circle: ""
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_el_table_column, { width: "56" }, {
                    default: _withCtx(({ $index }) => [
                      _createVNode(_component_el_tooltip, { content: "Удалить" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            icon: _ctx.Delete,
                            type: "danger",
                            circle: "",
                            onClick: ($event: any) => (_ctx.handleDelete(city, $index))
                          }, null, 8, ["icon", "onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["data"])
            ])
          ], 64))
        }), 128))
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.loading]
    ])
  ], 64))
}