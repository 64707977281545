import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: "text-nowrap cursor-pointer",
      style: {"display":"flex"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = !_ctx.dialogVisible))
    }, [
      _createTextVNode(_toDisplayString(_ctx.loading ? '...' : _ctx.pointName), 1),
      _createVNode(_component_el_icon, { class: "el-icon--right" }, {
        default: _withCtx(() => [
          _createVNode(_component_arrow_down)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "Изменить ПВЗ",
      "append-to-body": "",
      class: "custom-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          size: "large",
          style: {"width":"100%"},
          modelValue: _ctx.pointName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pointName) = $event)),
          filterable: "",
          placeholder: "Select",
          onChange: _ctx.selectPvz
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.allowPaths, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.pointId,
                label: item.pointAbv,
                value: item
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"]),
        _createVNode(_component_el_button, {
          size: "large",
          style: 'min-width: 116px; margin-top: 16px;',
          type: "primary",
          onClick: _ctx.changePoint
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Подтвердить")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}