import { AuthDto } from '@/models/api/auth-dto.interface';
import cookie from 'cookiejs';
export const TOKEN_KEY = 'token';

export function getToken() {
  const raw = cookie.get(TOKEN_KEY) as string;
  const token = raw ? (JSON.parse(raw) as AuthDto) : null;

  return token?.access_token;
}

export function getLocal() {
  const raw = cookie.get(TOKEN_KEY) as string;
  const token = raw ? (JSON.parse(raw) as AuthDto) : null;

  return token;
}

export function getRefresh() {
  const raw = cookie.get(TOKEN_KEY) as string;
  const token = raw ? (JSON.parse(raw) as AuthDto) : null;

  return token?.refresh_token;
}

export function setToken(payload: AuthDto) {
  cookie.set(TOKEN_KEY, JSON.stringify(payload), { secure: true, expires: 10, sameSite: 'Strict' })
  // localStorage.setItem(TOKEN_KEY, JSON.stringify(payload));
}

export function clearToken() {
  cookie.remove(TOKEN_KEY);
  // localStorage.removeItem(TOKEN_KEY);
}
