<template>
  <ui-header>
    Статусы
  </ui-header>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="form" @submit.prevent="findById">
      <el-form-item prop="orderId">
        <el-input class="search-input" v-model="form.orderId" style="width: 100%; max-width: 100%;" clearable
          placeholder="Введите номер заказа" size="large" @clear="clearForm" />
        <div class="flex-box">
          <el-button @click="findById" type="primary" size="large" :disabled="isLoading" :loading="isLoading"
            :style="'min-width: 116px'">
            Искать
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto" v-if="!isSearch"
        @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер заказа" />
        <el-table-column sortable="custom" prop="status" label="Статус" />
        <el-table-column sortable="custom" prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="pointAbv" label="ПВЗ назначения" />
        <el-table-column sortable="custom" prop="pointAddress" label="Адрес ПВЗ назначения" />
        <el-table-column sortable="custom" prop="daysInTransit" label="Дней в транзите" />
        <el-table-column sortable="custom" prop="payed" label="Дата оплаты">
          <template #default="{ row }">
            {{ formatDate(row.payed) }}
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="searchElements.data" :border="true" empty-text="Нет данных" table-layout="auto" v-else
        @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер заказа" />
        <el-table-column sortable="custom" prop="status" label="Статус" />
        <el-table-column sortable="custom" prop="modtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.modtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="pointAbv" label="ПВЗ назначения" />
        <el-table-column sortable="custom" prop="pointAddress" label="Адрес ПВЗ назначения" />
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length && !isSearch" :total="elements.total"
        :current-page="page" @change="setPage" />
    </div>
  </ui-main>

  <ui-main style="margin-top: 24px" v-loading="loadingComment" v-if="comments && isSearch">
    <h5 style="margin-bottom: 16px;">Комментарии</h5>
    <el-form ref="formRef" :model="form" @submit.prevent="addComment">
      <el-form-item prop="orderId">
        <el-input type="textarea" v-model="formComment.comment" placeholder="Комментарий..." />
        <el-button @click="addComment" type="primary" size="large" :disabled="isLoading" :loading="isLoading"
          :style="'min-width: 116px; margin-top: 16px; margin-left: 16px'">
          Добавить
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="comments" style="width: 100%" table-layout="auto">
      <el-table-column prop="comment" label="Комментарий" />
      <el-table-column prop="modtime" label="Дата создания">
        <template #default="{ row }">
          {{ formatDate(row.modtime) }}
        </template>
      </el-table-column>
    </el-table>
    <!-- <ul>
      <li v-for="item in comments" :key="item.modtime" style="font-size: 16px; line-height: 200%; border-top: 1px solid #ebeef5;"><cite>{{ item.comment }}</cite></li>
    </ul> -->
  </ui-main>
  <ui-main v-if="products && products.length > 0" style="margin-top: 24px">
    <p class="title">Состав заказа</p>
    <ul class="package-items">
      <li v-for="product in products" :key="product.prodLink">
        <div class="text-block">
          <copy :text="product.skuBarcode" />
          <a :href="product.prodLink" target="__blank">
            {{ product.productName }}
          </a>
        </div>
        <div class="price-block">
          <span>
            {{ `${product.quantity} шт.` }}
          </span>
          <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z"
              fill="#C2C4CC" />
          </svg>
          <span>
            {{ `${product.sumPrice}₽` }}
          </span>
        </div>
      </li>
    </ul>
  </ui-main>
</template>

<script lang="ts">
import { Delete, Edit } from '@element-plus/icons-vue';
import { defineComponent, ref } from 'vue';

import { getStatuses, findStatus, getStatusComments, addStatusComment, getStatusProducts } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { StatusesDto, SearchStatusesDto } from '@/models/api/statuses-dto.interface';
import { dateFormat } from '@/utils/date-format';
import Copy from '@/components/copy.vue'
import { StringInfo } from '@/models/api/statuses-dto.interface'
import { DEFAULT_PAGEABLE_LIST } from '@/utils/constants';
export default defineComponent({
  name: 'statuses',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
    Copy
  },
  setup() {
    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<StatusesDto>(getStatuses);
    const formatDate = (date: string) => dateFormat(date);
    const form = ref({
      orderId: ''
    });

    const loadingComment = ref(false)
    const isSearch = ref(false)
    const comments = ref<any>();
    const products = ref<StringInfo[] | null>();
    const searchElements = ref<any>(DEFAULT_PAGEABLE_LIST);

    const formComment = ref({
      orderId: '',
      comment: '',
    })

    const findById = async () => {
      isLoading.value = true;
      try {
        searchElements.value.data = await findStatus(form.value.orderId);
        comments.value = await getStatusComments(form.value.orderId);
        products.value = await getStatusProducts(form.value.orderId);
        formComment.value.orderId = form.value.orderId;
        isSearch.value = true
      } finally {
        isLoading.value = false;
      }
    }

    const clearForm = () => {
      isSearch.value = false
      getElements();
      form.value.orderId = '';
      comments.value = undefined;
    }

    const addComment = async () => {
      loadingComment.value = true;
      try {
        await addStatusComment(formComment.value).then(async () => {
          comments.value = await getStatusComments(form.value.orderId);
        });
      } finally {
        loadingComment.value = false;
        formComment.value.comment = ''
      }
    }

    return {
      Edit,
      Delete,
      isLoading,
      elements,
      page,
      setPage,
      formatDate,
      form,
      findById,
      sortChange,
      comments,
      formComment,
      loadingComment,
      addComment,
      getElements,
      clearForm,
      isSearch,
      products,
      searchElements
    };
  },
});
</script>

<style lang="stylus" scoped>
.title
  color: var(--el-text-color-primary)
  font-size: 16px
  font-weight: 700
  margin-bottom: 16px;
  width: 100%;
.package-count
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 14px
  @media (max-width 768px)
    flex-direction: column
    justify-content: center

.package-items
  li
    font-size: 16px
    line-height: 24px
    margin-bottom: 12px
    display: flex;
    justify-content: space-between
    max-width: 100%
    gap: 16px
    .text-block
      display: flex;
      gap 16px
      align-items: center
    .price-block
      display: flex;
      gap 8px
      align-items: center
    @media (max-width 768px)
      max-width 100%
      width: 100%
      align-items: center
    a
      color: var(--el-color-primary-dark-2)
      text-decoration: underline
      // border-bottom: 1px solid currentColor


    span
      font-weight 500
      white-space: nowrap
</style>
