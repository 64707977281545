import axios from 'axios';
import { API_URL } from '@/utils/constants';
import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';

export interface OrdersWithCodeErrors {
  pointId: string;
  orderId: string;
  errorCount: number;
}

export const getOrdersWithCodeErrors = async (
  params: PaginationParams,
): Promise<Pageable<OrdersWithCodeErrors>> => {
  const response = await axios
    .get(API_URL + 'order-pickup/ordersWithCodeErrors', { params })
    .then((response) => ({
      data: {
        data: response.data,
        total: response.data.length,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};

export const resetCurrentError = async (
  pointId: string,
  orderId: string,
): Promise<string> => {
  const response = await axios
    .delete(API_URL + `order-pickup/ordersWithCodeErrors/${pointId}/${orderId}`)
  return response.data;
};

export const resetAllErrors = async () => {
  const response = await axios
    .delete(API_URL + `order-pickup/deleteAllErrorData`)
  return response.data;
}




