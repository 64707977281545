<template>
  <div :class="isCollapse ? 'collapse' : ''"
    class="ui-nav">
    <router-link to="/" class="ui-nav__logo_link">
      <ui-icon class="ui-nav__logo" :class="isCollapse ? 'hidden-logo' : ''" :icon="iconName" :width="logoWidth"
        :height="mobileSize ? 20 : 24" />
    </router-link>
    <div class="ui-nav__menu">
      <template v-for="menuItem in menuItems" :key="menuItem.url">
        <router-link v-if="isAllow(menuItem.allow)" :to="menuItem.url" class="ui-nav__menu-item"
          :class="{ active: isActive(menuItem.url) }">
          <div class="el-badge custom-badge">
            <ui-icon :icon="menuItem.icon" :width="mobileSize ? 20 : 24" :height="mobileSize ? 20 : 24"
              class="ui-nav__menu-icon" />
            <sup class="el-badge__content" v-if="menuItem.setBadge && count > 0">{{ count }}</sup>
          </div>
          <span class="ui-nav__menu-title" :class="{ 'hidden': isCollapse }">
            {{ menuItem.name }}
          </span>
        </router-link>
      </template>
    </div>

    <el-button type="danger" link class="ui-nav__logout" @click="handleLogout">
      <span class="ui-nav__menu-title_logout" :class="{ 'hidden': isCollapse }">
        Выйти
      </span>
      <ui-icon icon="logout" class="ui-nav__logout-icon" :width="mobileSize ? 20 : 24" :height="mobileSize ? 20 : 24" />
    </el-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import UiIcon from '@/components/ui/ui-icon.vue';
import UiLogo from '@/components/ui/ui-logo.vue';
import { useUserStore } from '@/store/use-user-store';
import { MenuItems } from '@/utils/get-menu-items';
import { useMediaQuery } from '@vueuse/core';

export default defineComponent({
  name: 'ui-nav',
  components: {
    UiLogo,
    UiIcon,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const collapse = ref(false);
    const store = useUserStore();
    const iconName = ref(`mobile-${process.env.VUE_APP_THEME}-logo`)
    const logoWidth = ref(24);
    const mobileSize = useMediaQuery('(max-width: 768px)');
    const count = ref(0);

    const isCollapse = computed(() => {
      if (mobileSize.value) {
        return true
      }
      else return collapse.value
    })

    const changeCollapse = (isActive: boolean) => {
      collapse.value = isActive
    };
    const handleLogout = () => {
      router.push({ name: 'logout' });
    };
    const isAllow = (allow: string[]) => {
      return allow.find((role) => role === store.role);
    };

    const isActive = (path: string) => route.path.startsWith(path);



    //isCollapse && mobileSize ? 20 : isCollapse ? 24 : 200
    watch(() => isCollapse.value, () => {
      if (isCollapse.value) {
        iconName.value = `mobile-${process.env.VUE_APP_THEME}-logo`
        logoWidth.value = 24
      }
      else {
        iconName.value = `${process.env.VUE_APP_THEME}-logo`
        logoWidth.value = 200
      }
    });

    watch(()=> userStore.expCount,()=>{
      count.value = userStore.expCount;
    });

    onMounted(async () => {
      count.value = userStore.expCount;
    });


    return {
      menuItems: MenuItems,
      user: userStore.user,
      handleLogout,
      isActive,
      isCollapse,
      mobileSize,
      changeCollapse,
      iconName,
      logoWidth,
      isAllow,
      count
    };
  },
});
</script>

<style lang="stylus" scoped>
.ui-nav
  height 100%
  padding 16px
  display flex
  flex-direction column
  background-color var(--el-color-primary-dark)
  font-weight 400
  font-size 16px
  line-height 24px
  width: 232px
  transition:  all 0.5s
  position relative

  &__logo
    margin-bottom 56px
    width 100%
    animation: myAnim 0.5s;
    // opacity: 1
    // transition:  all 0.5s

  &__menu
    flex-grow 1
    display flex
    flex-direction column

  &__menu-item
    padding 12px 0
    display flex
    color rgba(white, 0.64)
    &.active
      color white

  &__menu-title
    margin-left 12px
    width: 155px
    transition:  all 0.5s
    white-space nowrap

  &__menu-title_logout
    margin-right 12px
    width: 44px
    transition:  all 0.5s


  &__logout
    padding 12px 0
    justify-content flex-start

  @media(max-width: 768px)
    max-width 36px
    padding 16px 8px

  .hidden
    transition:  all 0.5s
    width: 0
    opacity 0
    margin-right: 0
    margin-left: 0
    white-space nowrap
  .hidden-logo
    animation: myAnim 0.5s;

.collapse
  transition: all 0.5s
  width: 58px

@keyframes myAnim {
	0% {
		opacity: 0 !important;
	}

  100% {
		opacity: 1;
	}
}


.custom-badge
  position: relative;
  vertical-align: middle;
  display: inline-block;

  .el-badge__content
    position: absolute
    top: -11px !important;
    right: -14px !important;
    background-color: red
    border-radius: var(--el-badge-radius);
    color: var(--el-color-white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    height: var(--el-badge-size);
    padding: 0 3px;
    white-space: nowrap;
    border: none !important

</style>
