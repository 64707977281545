<template>
  <main class="ui-main" :class="{ 'ui-main--center': center }">
    <slot />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ui-main',
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="stylus" scoped>
.ui-main
  padding 24px
  display flex
  flex-direction column
  background-color var(--color-white)
  border-radius 8px
  position relative
  z-index 2

  &--center
    align-items center
</style>
