import axios from 'axios';

import { API_URL } from '@/utils/constants';
import { PickPointsRootDto } from '@/models/api/pickpoint-dto.interface';

export const getPickPoints = async (): Promise<PickPointsRootDto> => {
  return axios.get(API_URL + 'fias-pvz').then((response) => response.data);
};

export const updatePickPoints = async (data: PickPointsRootDto): Promise<PickPointsRootDto> => {
  return axios.post(API_URL + 'fias-pvz/update', data).then((response) => response.data);
};
