import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!

  return (_openBlock(), _createBlock(_component_ui_icon, {
    icon: _ctx.iconName,
    width: _ctx.width,
    height: _ctx.height
  }, null, 8, ["icon", "width", "height"]))
}