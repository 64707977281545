<template>
  <div class="error">
    <h1 class="error__title">Страница не найдена</h1>
    <p class="error__text">
      Возможно, эта страница больше не&nbsp;существует или переехала
      в&nbsp;другой раздел.
    </p>
    <p class="error__text">
      Вы&nbsp;можете перейти на
      <router-link to="/">главную страницу.</router-link>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: '404',
});
</script>

<style lang="stylus" scoped>
.error
  height 100%
  display flex
  flex-direction column
  align-items center
  justify-content center

  &__title
    h1()
    margin-bottom 32px

  &__text
    margin-bottom 24px
    font-size 16px
    line-height 24px

    a
      color var(--el-color-danger)

      &:hover
        color var(--el-color-danger-light-3)

      &:active
        color var(--el-color-danger-dark-2)
</style>
