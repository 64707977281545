<template>
  <ui-icon :icon="iconName" :width="width" :height="height" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useMediaQuery } from '@vueuse/core'
import UiIcon from '@/components/ui/ui-icon.vue';

export default defineComponent({
  name: 'ui-logo',
  components: {
    UiIcon,
  },
  props: {
    width: {
      type: [Number, String],
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
  },
  setup() {
    const iconName = `${process.env.VUE_APP_THEME}-logo`
    return {
      iconName,
    };
  },
});
</script>
