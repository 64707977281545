import axios from 'axios';
import { ElNotification } from 'element-plus';
import type { Router } from 'vue-router';

import {
  AUTHORIZATION_ERROR_TEXT,
  ERROR_TEXT,
  LOGIN_PATH,
  FAULT_TOKEN_ERROR_TEXT
} from '@/utils/constants';
import { refresh } from './refresh';
import { getToken, getRefresh, clearToken, setToken } from '@/utils/token';

// Auxiliary variable to store all api routes, in case of a custom error handling is needed
const HIDE_TOAST_MESSAGE_ON_ERROR_ROUTES = [LOGIN_PATH];

export const initiateAxios = (router: Router) => {
  axios.interceptors.request.use(
    (config) => {
      config.baseURL = process.env.VUE_APP_API_URL;
      const token = getToken();

      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      let message = ERROR_TEXT;
      const config = error?.config;
      const refreshToken = getRefresh();

      if (error?.response?.status === 401 && refreshToken) {
        try {
          const data = await refresh(refreshToken);
          if (data?.access_token) {
            config.headers = {
              ...config.headers,
              authorization: `Bearer ${data?.access_token}`,
            };
            setToken(data);
            return axios(config); // Повторяем запрос с обновленным токеном
          } else {
            message = AUTHORIZATION_ERROR_TEXT;
            router.push({ name: 'logout' });
            return Promise.reject(error);
          }
        } catch (refreshError) {
          clearToken();
          message = AUTHORIZATION_ERROR_TEXT;
          router.push({ name: 'logout' });
          return Promise.reject(error);
        }
      }

      if (error.response?.status === 500 && error.response?.data.error === 'Internal Server Error') {
        message = FAULT_TOKEN_ERROR_TEXT;
        router.push({ name: 'logout' });
      }

      if (
        !HIDE_TOAST_MESSAGE_ON_ERROR_ROUTES.some((path) =>
          error.config.url.includes(path),
        ) && error.response?.status !== 418
      ) {
        message = error.response.data
        ElNotification({position: 'bottom-right', type: 'error', message});
      }

      return Promise.reject(error);
    },
  );
};
