<template>
  <el-pagination
    v-if="total > 0"
    class="ui-pagination"
    layout="prev, pager, next"
    background
    :total="total"
    :current-page="currentPage"
    :page-size="pageSize"
    @current-change="$emit('change', $event)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const DEFAULT_PAGE_SIZE = Math.round((window.innerHeight - (80+68+48+50+63+41)) /41 );

export default defineComponent({
  name: 'ui-pagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: DEFAULT_PAGE_SIZE,
    },
  },
  emits: ['change'],
});
</script>

<style lang="stylus" scoped>
.ui-pagination
  margin-top 32px
</style>
