<template>
  <ui-header>
    Приемка Распределительного Центра
  </ui-header>
  <ui-main style="margin-bottom: 24px;" v-loading="isLoading">
    <el-form ref="formRef" :model="form" @submit.prevent="pickUp()">
      <el-form-item style="margin-bottom: 0px;" prop="orderId">
        <el-input style="width: 100%; max-width: 100%;" clearable placeholder="Введите номер заказа" size="large"
          class="search-input" v-model="form.orderId" />
        <div class="flex-box">
          <div>
            <el-button size="large" @click="pickUp()" type="primary" :disabled="isLoading" :loading="isLoading"
              :style="'min-width: 116px'">
              Принять
            </el-button>
            <!-- <el-popover :visible="showConfirm" placement="bottom" :width="260">
              <p>Пакет уже существует, добавить еще один?</p>
              <div style="text-align: right; margin: 0">
                <el-button size="small" text @click="showConfirm = false">Нет</el-button>
                <el-button size="small" type="primary" @click="pickNew()">Да</el-button>
              </div>
              <template #reference>
                <div></div>
              </template>
</el-popover> -->
          </div>
        </div>
      </el-form-item>
    </el-form>
  </ui-main>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="searchForm" label-position="top" @submit.prevent="searchOrder()">
      <el-form-item :prefix-icon="Search" style="margin-bottom: 16px;" prop="orderId">
        <el-input size="large" placeholder="Поиск заказа" :prefix-icon="Search" class="search-input" clearable
          v-model="searchForm.orderId" @input="searchOrder()" @clear="clearForm()" />
      </el-form-item>
    </el-form>
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto"
        @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер Заказа" />
        <el-table-column sortable="custom" prop="quantity" label="Мест" />
        <el-table-column sortable="custom" prop="created" label="Дата создания">
          <template #default="{ row }">
            {{ formatDate(row.created) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="statusName" label="Статус Заказа" />
        <el-table-column sortable="custom" prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="direction" label="Направление" />
        <el-table-column sortable="custom" prop="pointAbv" label="Шифр ПВЗ" />
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length" :total="elements.total" :current-page="page"
        @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { getDistributionReceived, pickupDistributionOrder } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { ReceivedDistributionDto } from '@/models/api/routing-center-dto.interface';
import { dateFormat } from '@/utils/date-format';
import { ElNotification } from 'element-plus';
import { ElMessageBox } from 'element-plus'
import { h } from 'vue'
import { DEFAULT_PARAMS } from '@/utils/constants';
import { Search } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'distribution-pick-up',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
  },
  setup() {
    const form = ref({
      orderId: '',
    });

    const timer = ref<any>(null);

    const isSearch = ref(false);

    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<ReceivedDistributionDto>(getDistributionReceived);

    const formatDate = (date: string) => dateFormat(date);


    const pickUp = async (isNew: boolean = true) => {
      const orderId = form.value.orderId;
      await pickupDistributionOrder(orderId, isNew).then((data) => {
        if (data === 'valid') {
          getElements();
          ElNotification({ position: 'bottom-right', type: 'success', message: data });
        } else if (data === 'invalid') {
          ElMessageBox({
            title: 'Пакет уже существует',
            message: h('p', null, [
              h('span', null, 'Добавить еще один?'),
            ]),
            showCancelButton: true,
            confirmButtonText: 'Да',
            cancelButtonText: 'Нет',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                pickNew()
                done()
              } else {
                done()
              }
            },
          })
        }
      })
    }

    const pickNew = () => {
      pickUp(false)
    }

    const searchForm = ref({
      orderId: '',
    });

    const searchOrder = () => {
      clearTimeout(timer.value);
      isSearch.value = true;
      timer.value = setTimeout(() => {
        getElements({
          orderIdFilter: searchForm.value.orderId,
          ...DEFAULT_PARAMS
        })
      }, 500);
    }

    const clearForm = () => {
      isSearch.value = false
      getElements();
      searchForm.value.orderId = ''
    }


    watch(()=> searchForm.value, ()=> {
      if (searchForm.value.orderId.length === 0){
        clearForm()
      }
    })

    return {
      isLoading,
      elements,
      page,
      setPage,
      formatDate,
      form,
      pickUp,
      pickNew,
      sortChange,
      clearForm,
      searchOrder,
      Search,
      searchForm
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
