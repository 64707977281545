import { AllowPathsDto } from "@/models/api/auth-dto.interface";
import { API_URL } from "@/utils/constants";
import axios from "axios";

export const assignPoint = async (username: string, pointId: number | null): Promise<string> => {
  return axios.post(API_URL + 'user-management/assign-point', null, {
    params: {
      username,
      pointId
    }
  }).then((response) => response.data);
};

export const getPoints = async (username: string): Promise<AllowPathsDto> => {
  return axios.get(API_URL + `user-management/${username}/points`,).then((response) => response.data[0]);
};

export const deletePoint = async (username: string, pointId: number): Promise<string> => {
  return axios.delete(API_URL + `user-management/${username}/remove-point/${pointId}`,).then((response) => response.data);
};

export const updatePoint = async (username: string, pointId: number | null, newPointId: number | null): Promise<string> => {
  return axios.put(API_URL + `user-management/${username}/update-point`, null, {
    params: {
      username,
      pointId,
      newPointId
    }
  }).then((response) => response.data);
};
