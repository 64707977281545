import axios from 'axios';

import { AuthDto } from '@/models/api/auth-dto.interface';
import { REFRESH_PATH } from '@/utils/constants';
import { setToken } from '@/utils/token';

export const refresh = async (token: string): Promise<AuthDto> => {
  const form = JSON.stringify({
    refreshToken: token,
  })
  return axios({
    method: "post",
    url: REFRESH_PATH,
    transformRequest: [function (data, headers) {
      if (headers){
        delete headers["Authorization"]
        headers['refresh-token'] = token
        headers['Content-Type'] = 'application/json'
      }
      data = JSON.stringify({
        refreshToken: token,
      })
      return data;
    }],
    // data: form,
  }).then((response) => {
    setToken(response.data)
    return response.data
  });
};
