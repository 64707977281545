<template>
  <div>
    <ui-header with-back-button>
      {{ pvzId ? 'Редактирование ПВЗ' : 'Добавление ПВЗ' }}
    </ui-header>

    <ui-main>
      <el-form ref="formRef" :model="form" class="form" label-width="160px"
        @submit.prevent="() => !!pvzId ? updatePvz() : addPvz()">
        <el-form-item label="Город" prop="city">
          <el-autocomplete v-model="form.city" :fetch-suggestions="allCities">
            <!-- <template #default="{ item }">
              <div class="value">{{ item }}</div>
            </template> -->
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="Город не в поиске" prop="form.customCity">
          <el-switch size="large" v-model="form.customCity" />
        </el-form-item>
        <el-form-item label="Полный адрес" prop="points[0].value">
          <el-input v-model="form.points[0].value" />
        </el-form-item>
        <el-form-item label="ФИАС" prop="points[0].id">
          <el-input v-model="form.points[0].id" />
        </el-form-item>
        <el-form-item label="Широта" prop="points[0].latitude">
          <el-input v-model="form.points[0].latitude" />
        </el-form-item>
        <el-form-item label="Долгота" prop="points[0].longitude">
          <el-input v-model="form.points[0].longitude" />
        </el-form-item>
        <el-form-item label="Режим работы" prop="form.points[0].workingTime">
          <el-input v-model="form.points[0].workingTime" />
        </el-form-item>
        <el-form-item label="Доп. информация" prop="form.points[0].additionalField">
          <el-input type="textarea" v-model="form.points[0].additionalField" />
        </el-form-item>
        <el-form-item label="Отделение почты" prop="form.points[0].isPost">
          <el-switch size="large" v-model="form.points[0].isPost" />
        </el-form-item>
        <el-form-item label="Платная доставка" prop="form.points[0].isPayed">
          <el-switch size="large" v-model="form.points[0].isPayed" />
        </el-form-item>

        <el-form-item label="Дата открытия">
          <el-date-picker v-model="form.points[0].dateOpened" type="date" placeholder="Выберите дату" size="default" />
        </el-form-item>
        <el-button @click="() => !!pvzId ? updatePvz() : addPvz()" type="primary" size="large">Подтвердить</el-button>
      </el-form>
    </ui-main>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import { usePvzStore } from "@/store/use-pvz-store";
import { getPickPoints, updatePickPoints } from "@/api";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";


const initialForm = {
  city: '',
  customCity: false,

  points: [
    {
      id: '',
      value: '',
      latitude: 0,
      longitude: 0,
      workingTime: '',
      additionalField: '',
      dateOpened: new Date(),
      isPost: false,
      isPayed: false,
    }
  ]
}

export default defineComponent({
  name: 'pvz-form',

  components: {
    UiMain,
    UiHeader,
  },
  props: {
    pvzId: {
      type: [Number, String],
      default: undefined,
    },
    city: {
      type: String,
      default: undefined,
    },
  },

  setup(props) {
    const form = ref(initialForm);
    const index = ref<number>(-1);
    const loading = ref(false)
    const pvzStore = usePvzStore()
    const router = useRouter()


    const getPoints = async () => {
      loading.value = true
      await getPickPoints().then(data => {
        pvzStore.initPvzStore(data)
      }).finally(() => {
        loading.value = false
      })
    }

    const allCities = (string: string, cb: any) => {
      const filteredCities = pvzStore.citiesName?.filter(city => city.value.includes(string))
      cb(filteredCities)
    }

    const initValue = () => {
      if (props.pvzId) {
        const findCity = pvzStore.checkout?.checkoutFias.find(elem => elem.city == props.city);
        if (findCity) {
          index.value = findCity.points.findIndex((point) => point.id == props.pvzId);
        }

        if (index.value != undefined && index.value >= 0) {
          const findPoint = findCity?.points[index.value];
          if (findCity && findPoint)
            form.value = {
              city: findCity.city,
              customCity: findCity.customCity,
              points: [findPoint]
            }
        }

      }
    }

    onMounted(() => {
      if (!pvzStore.checkout) {
        getPoints().then(() => {
          initValue()
          return
        })
      }
      initValue()

    })

    const isEmptyObject = (obj: any) => {
      const filteredObject = JSON.parse(JSON.stringify(obj));
      delete filteredObject.additionalField
      return Object.values(filteredObject).some(field => {
        if (typeof field === 'number' && field === 0) {
          return true;
        } else if (typeof field === 'string' && field.trim() === '') {
          return true;
        }
        return false;
      });
    }

    const trimAll = () => {
      form.value.city = form.value.city.trimStart()
      let pointKeys = Object.keys(form.value.points[0])
      let point = form.value.points[0]
      pointKeys.map(key => {
        //@ts-ignore
        if (typeof point[key] === 'string' || point[key] instanceof String) {
          //@ts-ignore
          point[key] = point[key].trimStart();
          //@ts-ignore
          point[key] = point[key].trimRight();
        }
      })
    }

    const updatePvz = async () => {
      if (form.value.city == '' || isEmptyObject(form.value.points[0])) {
        ElNotification({ position: 'bottom-right', type: 'error', message: 'Заполните все поля' });
        return;
      }
      trimAll()
      if (index.value >= 0)
        pvzStore.editPvz(form.value, index.value);
      if (pvzStore.checkout) {
        await updatePickPoints(pvzStore.checkout).then(() => {
          ElNotification({ position: 'bottom-right', type: 'success', message: 'Успешно обновлен' });
          router.back()
        });
      }

    }

    const addPvz = async () => {
      if (form.value.city == '' || isEmptyObject(form.value.points[0])) {
        ElNotification({ position: 'bottom-right', type: 'error', message: 'Заполните все поля' });
        return;
      }
      trimAll()
      pvzStore.addPvz(form.value)
      if (pvzStore.checkout) {
        await updatePickPoints(pvzStore.checkout).then(() => {
          ElNotification({ position: 'bottom-right', type: 'success', message: 'Успешно добавлен' });
          router.back()
        });
      }
    }


    return {
      form,
      updatePvz,
      addPvz,
      pvzStore,
      allCities,
      initValue,
      index
    }
  }

})
</script>

<style scoped></style>
