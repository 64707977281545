<template>
  <ui-header>
    В пути в ПВЗ
    <template #right>{{`${store.currentPath?.pointAbv}${store.currentPath?.partnerPointUid ? ` (${store.currentPath?.partnerPointUid})` : ''}` }}</template>
  </ui-header>
  <ui-main v-loading="isLoading">
    <el-form ref="formRef" :model="searchForm" label-position="top" @submit.prevent="searchOrder()">
      <el-form-item :prefix-icon="Search" style="margin-bottom: 16px;" prop="orderId">
        <el-input size="large" placeholder="Поиск заказа" :prefix-icon="Search" class="search-input" clearable
          v-model="searchForm.orderId" @input="searchOrder()" @clear="clearForm()" />
      </el-form-item>
    </el-form>
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto" @sort-change="sortChange">
        <el-table-column sortable="custom" prop="orderId" label="Номер заказа" />
        <el-table-column sortable="custom" prop="quantity" label="Мест" />
        <el-table-column sortable="custom"  prop="created" label="Дата создания">
          <template #default="{ row }">
            {{ formatDate(row.created) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="statusName" label="Статус заказа" />
        <el-table-column sortable="custom"  prop="statusModtime" label="Дата статуса">
          <template #default="{ row }">
            {{ formatDate(row.statusModtime) }}
          </template>
        </el-table-column>
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length && !isSearch" :total="elements.total" :current-page="page"
        @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import { getPickupLocation } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { PickupLocationDto } from "@/models/api/order-pickup-dto.interface";
import { dateFormat } from '@/utils/date-format';
import { useUserStore } from '@/store/use-user-store';
import { DEFAULT_PARAMS } from '@/utils/constants';
import { Search } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'pickup-location',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
  },
  setup() {

    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<PickupLocationDto>(getPickupLocation);

    const formatDate = (date: string) => dateFormat(date);

    const store = useUserStore();
    const timer = ref<any>(null);
    const isSearch = ref(false)

    const searchForm = ref({
      orderId: '',
    });

    const searchOrder = () => {
      clearTimeout(timer.value);
      isSearch.value = true;
      timer.value = setTimeout(() => {
        getElements({
          orderIdFilter: searchForm.value.orderId,
          ...DEFAULT_PARAMS
        })
      }, 500);
    }

    const clearForm = () => {
      isSearch.value = false
      getElements();
      searchForm.value.orderId = ''
    }


    watch(()=> searchForm.value, ()=> {
      if (searchForm.value.orderId.length === 0){
        clearForm()
      }
    })

    return {
      isLoading,
      elements,
      page,
      setPage,
      getElements,
      store,
      formatDate,
      searchForm,
      searchOrder,
      sortChange,
      isSearch,
      clearForm,
      Search,
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
