<template>
  <ui-header>
    Ошибки Выдачи
    <template #right>
      <el-button @click="resetAll" type="primary" size="large">Сбросить Все</el-button>
    </template>
  </ui-header>
  <ui-main v-loading="isLoading">
    <div class="items-center-column">
      <el-table :data="elements.data" :border="true" empty-text="Нет данных" table-layout="auto" @sort-change="sortChange">
        <el-table-column sortable="custom"  prop="pointId" label="ПВЗ">
          <template #default="{ row }">
            {{ pvzAbvById(row.pointId) }}
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="orderId" label="Номер Заказа" />
        <!-- <el-table-column sortable="custom" prop="" label="Номер Пакета" /> -->
        <el-table-column sortable="custom" prop="errorCount" label="Количество попыток" />
        <el-table-column sortable="custom"  prop="reset" label="Сбросить">
          <template #default="{ row }">
            <ui-icon @click="resetCurrent(row.pointId, row.orderId)" class="cursor-pointer"
              icon="refresh" :size="24"></ui-icon>
          </template>
        </el-table-column>
      </el-table>
      <ui-pagination v-if="elements.total > elements.data.length" :total="elements.total" :current-page="page"
        @change="setPage" />
    </div>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import { getOrdersWithCodeErrors, resetCurrentError, resetAllErrors } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { useGetElements } from '@/composables/use-get-elements';
import { OrdersWithCodeErrors } from "@/api/error-count-control";
import { dateFormat } from '@/utils/date-format';
import { useUserStore } from '@/store/use-user-store';
import UiIcon from '@/components/ui/ui-icon.vue';
import { ElNotification } from 'element-plus';

//  pointId: string;
// orderId: string;
//   packageId: string;
//   errorCount: number;

export default defineComponent({
  name: 'error-count',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
    UiIcon,
  },
  setup() {

    const { isLoading, page, setPage, elements, getElements, sortChange } =
      useGetElements<OrdersWithCodeErrors>(getOrdersWithCodeErrors);
    const store = useUserStore();
    const formatDate = (date: string) => dateFormat(date);
    const pvzAbvById = (pvz_id: number) => {
      return store.allowPaths?.find((pvz) => pvz.pointId == pvz_id)?.pointAbv
    };
    const resetAll = async () => {
      await resetAllErrors().then(() => getElements())
    };

    const extractId = (str: string) => {
      const match = str.substring(5);

      if (match) {
        return match.split('-')[0];
      }
      return ''
    };

    const resetCurrent = async (pointId: string, orderId: string,) => {
      await resetCurrentError(pointId, orderId).then(() => {
        getElements()
        ElNotification({ position: 'bottom-right', type: 'success', message: 'Успешно!' });
      })
    }
    return {
      isLoading,
      elements,
      page,
      setPage,
      formatDate,
      pvzAbvById,
      resetAll,
      resetCurrent,
      sortChange,
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
