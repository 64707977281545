import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_header = _resolveComponent("ui-header")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_ui_main = _resolveComponent("ui-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ui_header, null, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("Управление пользователями")
      ])),
      _: 1
    }),
    _withDirectives((_openBlock(), _createBlock(_component_ui_main, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          "label-position": "top",
          class: "form",
          onSubmit: _withModifiers(_ctx.showPoint, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  style: {"width":"100%","max-width":"100%"},
                  clearable: "",
                  placeholder: "Никнейм пользователя",
                  size: "large",
                  class: "search-input",
                  modelValue: _ctx.form.username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.username) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_el_button, {
                  type: "primary",
                  size: "large",
                  style: 'min-width: 116px',
                  onClick: _ctx.showPoint
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(_toDisplayString('Узнать ПВЗ'))
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            (_ctx.showPointForm)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "Текущий ПВЗ"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      size: "large",
                      modelValue: _ctx.pointName,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pointName) = $event)),
                      filterable: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.allowPaths, (point) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            onClick: ($event: any) => (_ctx.changePoint(point)),
                            key: point.pointId,
                            label: `${ point.isSorting === 0 ? point.pointAbv : `${point.pointAbv } (сортировочный центр)`}`,
                            value: point.pointAbv
                          }, null, 8, ["onClick", "label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showPointForm)
              ? (_openBlock(), _createBlock(_component_el_form_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "large",
                      style: 'min-width: 116px',
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSubmit()))
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(_toDisplayString('Изменить'))
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ]),
      _: 1
    })), [
      [_directive_loading, _ctx.isLoading]
    ])
  ], 64))
}