<template>
  <svg class="ui-icon" xmlns="http://www.w3.org/2000/svg" v-bind="iconSize">
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const DEFAULT_ICON_SIZE = 24;

type IconSize = {
  width: string | number;
  height: string | number;
};

export default defineComponent({
  name: 'ui-icon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: DEFAULT_ICON_SIZE,
    },
    width: {
      type: [Number, String],
      default: undefined,
    },
    height: {
      type: [Number, String],
      default: undefined,
    },
  },
  computed: {
    iconPath(): string {
      return `/assets/sprite.svg#${this.icon}`;
    },
    iconSize(): IconSize {
      if (this.width || this.height) {
        return {
          width: this.width ?? DEFAULT_ICON_SIZE,
          height: this.height ?? DEFAULT_ICON_SIZE,
        };
      }

      return {
        width: this.size,
        height: this.size,
      };
    },
  },
});
</script>

<style lang="stylus" scoped>
.ui-icon
  fill: currentColor
  display: block
</style>
