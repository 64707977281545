import { getExpiringCount } from "./../api/expiring-control";
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { login, getUserInfo, availablePoints } from '@/api';
import { AllowPathsDto, AuthDto } from '@/models/api/auth-dto.interface';
import { LoginDto } from '@/models/api/login-dto.interface';
import { clearToken, getLocal, setToken } from '@/utils/token';

// FIX ME: This was a workaround to fetch and store tokens to run project properly.
// Later, we need to fetch user details and store it as `user` variable.
// Tokens shouldn't be stored inside the state. They should be stored only inside a browser's local storage.
export const useUserStore = defineStore('user', () => {
  const user = ref<AuthDto | null>();
  const isAuthenticated = computed(() => user.value !== null);
  const role = ref<string>();
  const pvz_id = ref<null | string>('0');
  const allowPaths = ref<AllowPathsDto[] | undefined>();
  const currentPath = ref<AllowPathsDto | undefined>();
  const expCount = ref(0);

  const authorize = (data: LoginDto) => {
    return new Promise((resolve, reject) => {
      login(data)
        .then((response) => {
          user.value = response;
          setToken(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  const getRole = ()=> {
    return new Promise((resolve, reject) => {
      getUserInfo()
        .then((response) => {
          pvz_id.value = response.pvz_id;
          role.value = response.roles.pop();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(()=> getExpCount())
    });
  };

  const getLocalUser = async ()=> {
    user.value = getLocal()
    if(user.value){
      await getRole()
      await getAllowPaths();
      return true;
    }
    else return false
  }

  const getExpCount = async () => {
    if (role.value === "Pickpoint Admin" || role.value === "admin"){
      expCount.value = await getExpiringCount()
    }
  }

  const getAllowPaths = () => {
    return new Promise((resolve, reject) => {
      availablePoints()
        .then((response) => {
          allowPaths.value = response;
          if(allowPaths.value)
          getCurrentPath(allowPaths.value);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getCurrentPath = (paths : AllowPathsDto[]) => {
    currentPath.value = paths.find((pvz) => Number(pvz_id.value) == pvz.pointId)
  }

  const logout = () => {
    user.value = null;
    clearToken();
  };



  return {
    user,
    isAuthenticated,
    getRole,
    role,
    getAllowPaths,
    allowPaths,
    authorize,
    getCurrentPath,
    currentPath,
    logout,
    pvz_id,
    getLocalUser,
    expCount,
    getExpCount
  };
},{
  persist: {
    paths: [
      'isAuthenticated',
      'role',
      'pvz_id',
      'allowPaths',
      'currentPath',
      'expCount'
    ]
  },
});
