import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_tooltip, { content: "Возврат заказа" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          icon: _ctx.Edit,
          type: "danger",
          circle: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogVisible = !_ctx.dialogVisible))
        }, null, 8, ["icon"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      title: "Оформить возврат",
      "append-to-body": "",
      class: "custom-dialog"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          label: "комментарий",
          type: "textarea",
          modelValue: _ctx.form.comment,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.comment) = $event))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_el_button, {
          size: "large",
          style: 'min-width: 116px; margin-top: 16px;',
          type: "primary",
          onClick: _ctx.returnOrder
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Подтвердить")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}