import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_ctx.total > 0)
    ? (_openBlock(), _createBlock(_component_el_pagination, {
        key: 0,
        class: "ui-pagination",
        layout: "prev, pager, next",
        background: "",
        total: _ctx.total,
        "current-page": _ctx.currentPage,
        "page-size": _ctx.pageSize,
        onCurrentChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event)))
      }, null, 8, ["total", "current-page", "page-size"]))
    : _createCommentVNode("", true)
}