import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "ui-header" }
const _hoisted_2 = { class: "ui-header__left" }
const _hoisted_3 = { class: "ui-header__title" }
const _hoisted_4 = { class: "ui-header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_bold = _resolveComponent("arrow-left-bold")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.withBackButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "ui-header__back",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGoBack && _ctx.handleGoBack(...args)))
          }, [
            _createVNode(_component_el_icon, { size: "24" }, {
              default: _withCtx(() => [
                _createVNode(_component_arrow_left_bold)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "right", {}, undefined, true)
    ])
  ]))
}