import axios, { AxiosError } from 'axios';
import { API_URL } from '@/utils/constants';
import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';
import { ExpiringDto, ExpiringFormDto } from '@/models/api/expiring-dto.interface';
import { useUserStore } from '@/store/use-user-store';


export const getExpiringOrders= async (
  params: PaginationParams,
): Promise<Pageable<ExpiringDto>> => {
  const store = useUserStore()
  const response = await axios
    .get(API_URL + `expiring/${store.pvz_id}`, { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};


export const makeOrderReturn = async (resp: ExpiringFormDto) =>{
  const store = useUserStore();
  const response = await axios.post(API_URL + `expiring/makeOrderReturn/${store.pvz_id}`, resp).then(response => response)
  return response.data
}

export const getExpiringCount = async ():Promise<number> =>{
  const store = useUserStore();
  const response = await axios.get(API_URL + `expiring/${store.pvz_id}/count`).then(response=> response)
  return response.data
}
