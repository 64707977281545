import axios from 'axios';

import { AuthDto } from '@/models/api/auth-dto.interface';
import { LoginDto } from '@/models/api/login-dto.interface';
import { LOGIN_PATH } from '@/utils/constants';

export const login = async (data: LoginDto): Promise<AuthDto> => {
  return axios.post(LOGIN_PATH,data).then((response) => response.data);
};

