
export enum MenuIcon {
  Statuses = 'statuses',
  RoutingCenter= 'routing-center',
  DistributionPickUp = 'distribution-center',
  UserControl = 'user-control',
  OrderPickup = 'pickup',
  PickupLocation = 'pickup-location',
  PickupArrival = 'pickup-arrival',
  ErrorCount = 'error-count',
}
