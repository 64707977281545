import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ui-nav__menu" }
const _hoisted_2 = { class: "el-badge custom-badge" }
const _hoisted_3 = {
  key: 0,
  class: "el-badge__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_icon = _resolveComponent("ui-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.isCollapse ? 'collapse' : '', "ui-nav"])
  }, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "ui-nav__logo_link"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ui_icon, {
          class: _normalizeClass(["ui-nav__logo", _ctx.isCollapse ? 'hidden-logo' : '']),
          icon: _ctx.iconName,
          width: _ctx.logoWidth,
          height: _ctx.mobileSize ? 20 : 24
        }, null, 8, ["class", "icon", "width", "height"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: menuItem.url
        }, [
          (_ctx.isAllow(menuItem.allow))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: menuItem.url,
                class: _normalizeClass(["ui-nav__menu-item", { active: _ctx.isActive(menuItem.url) }])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ui_icon, {
                      icon: menuItem.icon,
                      width: _ctx.mobileSize ? 20 : 24,
                      height: _ctx.mobileSize ? 20 : 24,
                      class: "ui-nav__menu-icon"
                    }, null, 8, ["icon", "width", "height"]),
                    (menuItem.setBadge && _ctx.count > 0)
                      ? (_openBlock(), _createElementBlock("sup", _hoisted_3, _toDisplayString(_ctx.count), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["ui-nav__menu-title", { 'hidden': _ctx.isCollapse }])
                  }, _toDisplayString(menuItem.name), 3)
                ]),
                _: 2
              }, 1032, ["to", "class"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _createVNode(_component_el_button, {
      type: "danger",
      link: "",
      class: "ui-nav__logout",
      onClick: _ctx.handleLogout
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", {
          class: _normalizeClass(["ui-nav__menu-title_logout", { 'hidden': _ctx.isCollapse }])
        }, " Выйти ", 2),
        _createVNode(_component_ui_icon, {
          icon: "logout",
          class: "ui-nav__logout-icon",
          width: _ctx.mobileSize ? 20 : 24,
          height: _ctx.mobileSize ? 20 : 24
        }, null, 8, ["width", "height"])
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 2))
}