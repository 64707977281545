<template>
  <span class="text-nowrap cursor-pointer" style="display: flex;" @click="dialogVisible = !dialogVisible">
    {{ loading ? '...' : pointName }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
  </span>
  <el-dialog v-model="dialogVisible" title="Изменить ПВЗ" append-to-body class="custom-dialog">
    <el-select size="large" style="width: 100%;" v-model="pointName" filterable placeholder="Select" @change="selectPvz">
      <el-option v-for="item in store.allowPaths" :key="item.pointId" :label="item.pointAbv" :value="item" />
    </el-select>
    <el-button size="large" :style="'min-width: 116px; margin-top: 16px;'" type="primary"
      @click="changePoint">Подтвердить</el-button>
  </el-dialog>
</template>

<script lang="ts">
import { changePP } from '@/api/distribution-center'
import { computed, defineComponent, ref } from 'vue'
import UiIcon from '@/components/ui/ui-icon.vue';
import {
  ArrowDown,
} from '@element-plus/icons-vue'
import { useUserStore } from '@/store/use-user-store';
import { AllowPathsDto } from '@/models/api/auth-dto.interface';
export default defineComponent({
  name: 'order-point',
  components: {
    UiIcon,
    ArrowDown,
  },
  props: {
    orderId: {
      default: '',
    },
    pointAbv: {
      default: '',
    }
  },
  setup(props, { emit }) {
    const store = useUserStore();
    const loading = ref(false);
    const current = ref<any>(null);
    const pointName = computed(() => current.value ? current.value.pointAbv : props.pointAbv);
    const dialogVisible = ref<boolean>(false);


    const changePoint = async () => {
      loading.value = true;
      await changePP(props.orderId, current.value.pointId).then(() => {
        loading.value = false
        emit('change-value')
      }
      ).finally(() => {
        loading.value = false
        dialogVisible.value = false
      })
    }

    const selectPvz = (point: AllowPathsDto) => {
      current.value = point
    }

    return {
      store,
      pointName,
      changePoint,
      loading,
      dialogVisible,
      current,
      selectPvz
    }
  }
})
</script>

<style lang="stylus" scoped>

.custom-dialog
  width 600px
  @media (max-width: 800px ) {
    width 95%
  }

</style>
