<template>
  <ui-header>
    Управление ПВЗ
    <template #right>
      <router-link to="/pick-points/create">
        <el-button type="primary" size="large">
          Добавить
        </el-button>
      </router-link>
    </template>
  </ui-header>
  <ui-main v-loading="loading">
    <template v-for="city in cities" :key="city">
      <h5 style="margin:16px 0;">{{ city.city }}</h5>
      <div class="items-center-column">
        <el-table :data="city.points" :border="true" empty-text="Нет данных"  table-layout="auto">
          <el-table-column width="100" label="Открыт" style="font-weight: 500;">
            <template #default="{ row }">
              <span style="font-weight: 500; font-size: 16px;" :style="isSameOrBefore(row.dateOpened) ? {color:'green'} : {color: 'red'}">{{ isSameOrBefore(row.dateOpened) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="350px" prop="id" label="ФИАС" />
          <el-table-column prop="value" label="Адрес" />
          <el-table-column width="56">
            <template #default="{ row }">
              <el-tooltip content="Редактировать">
                <router-link :to="`/pick-points/edit/${city.city}/${row.id}`">
                  <el-button :icon="Edit" type="primary" circle />
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column width="56">
            <template #default="{ $index }">
              <el-tooltip content="Удалить">
                <el-button :icon="Delete" type="danger" circle @click="handleDelete(city, $index)" />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </ui-main>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Delete, Edit } from '@element-plus/icons-vue';
import { getPickPoints, updatePickPoints } from '@/api';
import UiHeader from '@/components/ui/ui-header.vue';
import UiMain from '@/components/ui/ui-main.vue';
import UiPagination from '@/components/ui/ui-pagination.vue';
import { usePvzStore } from '@/store/use-pvz-store';
import { useDeleteElement } from '@/composables/use-delete-element';
import { ElMessageBox } from 'element-plus';

export default defineComponent({
  name: 'pick-points',
  components: {
    UiPagination,
    UiMain,
    UiHeader,
  },
  setup() {
    const cities = ref<any>(null);
    const pvzStore = usePvzStore();
    const loading = ref(false);

    const getPoints = async () => {
      loading.value = true
      await getPickPoints().then(data => {
        cities.value = data.checkoutFias;
        pvzStore.initPvzStore(data)
      }).finally(() => {
        loading.value = false
      })
    }
    getPoints()

    const handleDelete = async (city: any, index: number) => {
      return ElMessageBox.confirm('Вы уверены, что хотите удалить ПВЗ', 'Удаление', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Отмена',
      }).then(async () => {
        pvzStore.deletePvz(city, index);
        if (pvzStore.checkout) {
          await updatePickPoints(pvzStore.checkout).then(() => getPoints())
        }
      });


    }

    const isSameOrBefore = (dateString: string)=> {
    // Парсим строку с датой в объект Date
    const date = new Date(dateString);

    // Получаем текущую дату и время
    const now = new Date();
      console.log([date.getTime(), now.getTime() ])
    // Сравниваем даты
    return date.getTime() < now.getTime();
}

    return {
      cities,
      getPoints,
      Edit,
      Delete,
      handleDelete,
      loading,
      isSameOrBefore
    };
  },
});
</script>

<style lang="stylus" scoped>

</style>
