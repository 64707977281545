import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ke-body" }
const _hoisted_2 = { class: "ke-body__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_mobile_nav = _resolveComponent("ui-mobile-nav")!
  const _component_ui_nav = _resolveComponent("ui-nav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mobileSize)
      ? (_openBlock(), _createBlock(_component_ui_mobile_nav, { key: 0 }))
      : (_openBlock(), _createBlock(_component_ui_nav, { key: 1 })),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}