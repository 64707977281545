<template>
  <div class="ke-body">
    <ui-mobile-nav v-if="mobileSize" />
    <ui-nav v-else />
    <div class="ke-body__main">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import UiNav from '@/components/ui/ui-nav.vue';
import UiMobileNav from '@/components/ui/ui-mobile-nav.vue';
import { useMediaQuery } from '@vueuse/core';
import { useUserStore } from '@/store/use-user-store';

export default defineComponent({
  name: 'default',
  components: {
    UiNav,
    UiMobileNav,
  },
  setup() {
    const mobileSize = useMediaQuery('(max-width: 768px)');
    const user = useUserStore()
    return {
      mobileSize
    }
  }
});
</script>

<style lang="stylus" scoped>
.ke-body
  display flex
  background-color var(--color-gray)
  height 100%
  overflow hidden

  &__main
    padding 40px
    overflow auto
    display flex
    flex-direction column
    flex-grow 1
    min-width 540px
    position relative
    z-index 2
    @media(max-width: 768px)
      padding 60px 10px
      min-width 300px
</style>
