<template>
  <ui-icon class="cursor-pointer" style="outline: none;" :icon="'phone'" @click="showPhone"></ui-icon>
  <el-dialog v-model="showDialog" title="Контакты" append-to-body class="custom-dialog">
    <div style="font-size: 18px;"><strong style="font-weight: 500; font-size: 20px;">Номер:&nbsp;</strong> {{ contacts.recPhone }}</div><br>
    <div style="font-size: 18px;"><strong style="font-weight: 500; font-size: 20px;">Email:&nbsp;</strong> {{ contacts.recEmail }}</div><br>
    <el-button @click="closeDialog" :style="'min-width: 116px; margin-top: 16px;'" size="large" type="primary">Закрыть</el-button>
  </el-dialog>
</template>

<script lang="ts">
// import { getPhoneNumber } from '@/api/distribution-center'
import { defineComponent, ref } from 'vue'
import UiIcon from '@/components/ui/ui-icon.vue';
import { getContactsByOrder } from '@/api/user-info';
export default defineComponent({
  name: 'show-phone',
  components: {
    UiIcon,
  },
  props: {
    orderId: {
      default: '',
    }
  },
  setup(props) {
    const contacts = ref<any>(null);
    const showDialog = ref(false)
    const showPhone = async () => {
      await getContactsByOrder(props.orderId).then((data) => {
        contacts.value = data
        showDialog.value = true
      })
    };

    const closeDialog = ()=>{
      showDialog.value = false
    }

    return {
      contacts,
      showPhone,
      showDialog,
      closeDialog
    }
  }
})
</script>

<style scoped></style>
