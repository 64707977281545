<template>
  <el-tooltip content="Возврат заказа">
    <el-button :icon="Edit" type="danger" circle @click="dialogVisible = !dialogVisible" />
  </el-tooltip>
  <el-dialog v-model="dialogVisible" title="Оформить возврат" append-to-body class="custom-dialog">
    <el-input label="комментарий" type="textarea" v-model="form.comment"></el-input>
    <el-button size="large" :style="'min-width: 116px; margin-top: 16px;'" type="primary"
      @click="returnOrder">Подтвердить</el-button>
  </el-dialog>
</template>

<script lang="ts">
import { makeOrderReturn } from '@/api'
import { Delete, Edit } from '@element-plus/icons-vue';
import { computed, defineComponent, reactive, ref } from 'vue'
import UiIcon from '@/components/ui/ui-icon.vue';
import { RemoveFilled } from '@element-plus/icons-vue'
import { useUserStore } from '@/store/use-user-store';
import { ExpiringFormDto } from '@/models/api/expiring-dto.interface';

export default defineComponent({
  name: 'order-return',
  components: {
    UiIcon,
    RemoveFilled,
  },
  props: {
    orderId: {
      default: '',
    },
  },
  setup(props, { emit }) {
    const store = useUserStore();
    const dialogVisible = ref<boolean>(false);

    const form = reactive<ExpiringFormDto>({
      comment: '',
      orderId: props.orderId
    });

    const returnOrder = async () => {

      await makeOrderReturn(form).then(() => {
        store.getExpCount()
        emit('update')
      }).finally(() => {
        dialogVisible.value = false;
      })
    }

    return {
      store,
      returnOrder,
      dialogVisible,
      form,
      Edit
    }
  }
})
</script>

<style lang="stylus" scoped>

.custom-dialog
  width 600px
  @media (max-width: 800px ) {
    width 95%
  }

</style>
