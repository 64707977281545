import axios from 'axios';
import { API_URL } from '@/utils/constants';

interface UserInfo {
  pvz_id: string | null;
  roles: string[];
  username: string;
}

interface Contacts {
  recEmail: string,
  recPhone: string,
}

export const getUserInfo = async (): Promise<UserInfo> => {
  return await axios.get(API_URL + 'user-info').then((response) => response.data);
};

export const availablePoints = async (): Promise<any> => {
  return await axios.get(API_URL + 'available-points').then((response) => response.data);
};
export const getContactsByOrder = async (orderId: number | string): Promise<Contacts> => {
  return await axios.get(API_URL + `customer-info/${orderId}/info`).then((response) => response.data);
};
//customer-info/{orderId}/info
