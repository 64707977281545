import { onMounted, Ref, ref, watch } from 'vue';

import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';
import { DEFAULT_PAGEABLE_LIST, DEFAULT_PARAMS } from '@/utils/constants';

export const useGetElements = <T>(
  getElements: (params: PaginationParams & any) => Promise<Pageable<T>>,
  {
    params: filterParams = {},
  }: {
    params?: { [key: string]: unknown };
  } = {},
): {
  isLoading: Ref<boolean>;
  page: Ref<number>;
  setPage: (value: number) => void;
  sortColumn: Ref<undefined | string>;
  elements: Ref<Pageable<T>>;
  getElements: (params?: PaginationParams  & any) => void;
  sortChange: (data: any) => void;
} => {
  const isLoading = ref(false);
  const page = ref<number>(DEFAULT_PARAMS.page);
  const data = ref<Pageable<T>>(DEFAULT_PAGEABLE_LIST) as Ref<Pageable<T>>;
  const sortColumn = ref<undefined | string>();
  const sortOrder = ref<undefined | string>();

  const sortChange = (data: any) => {
    const pagination = DEFAULT_PARAMS;
    pagination.page = page.value;
    sortColumn.value = data.prop;
    if (data.column.order == "ascending") {
      sortOrder.value = 'ASC'
      onGetData({ sortColumn: data.prop, ...pagination, sortDirection: 'ASC' })
    } else if (data.column.order == "descending") {
      sortOrder.value = 'DESC'
      onGetData({ sortDirection: 'DESC', sortColumn: data.prop, ...pagination })
    } else {
      data.column.order = null;
      sortColumn.value = undefined;
      sortOrder.value = undefined;
      onGetData({ ...DEFAULT_PARAMS, page: page.value });
    }
  }

  const onGetData = (params = DEFAULT_PARAMS): void => {
    isLoading.value = true;
    getElements({ ...params, ...filterParams })
      .then((response) => {
        data.value = response;
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const getData = (params = DEFAULT_PARAMS): void => {
    if (page.value !== 1 && data.value.data.length === 1) {
      setPage(1);
    } else {
      onGetData(params);
    }
  };

  const setPage = (value: number): void => {
    page.value = value;
  };

  onMounted(onGetData);

  watch(page, (value) => {
    if(sortColumn.value){
      onGetData({ ...DEFAULT_PARAMS, page: value, sortColumn: sortColumn.value, sortDirection: sortOrder.value  });
    } else
    onGetData({ ...DEFAULT_PARAMS, page: value});

  });

  return {
    isLoading,
    page,
    setPage,
    sortColumn,
    elements: data,
    getElements: getData,
    sortChange,
  };
};
