import { CheckoutFiasDto, PickPointsRootDto } from "@/models/api/pickpoint-dto.interface";
import { defineStore } from "pinia";
import { computed, ref } from 'vue';

export const usePvzStore = defineStore('pickPoints', () => {
  const checkout = ref<PickPointsRootDto | null>(null);

  const addPvz = (city: CheckoutFiasDto) => {
    const isOld = checkout.value?.checkoutFias.find(item => item.city === city.city);
    if (isOld) {
      isOld.points.push(...city.points);
    } else {
      checkout.value?.checkoutFias.push(city);
    }
  }

  const editPvz = (city: CheckoutFiasDto, index: number) => {
    const currentCity = checkout.value?.checkoutFias.find(item => item.city == city.city);
    if (currentCity) {
      currentCity.customCity = city.customCity;
      currentCity.points[index] = city.points[0]
    }
  }

  const deletePvz = (city: CheckoutFiasDto, index: number) => {
    const currentCity = checkout.value?.checkoutFias.find(item => item.city === city.city);
    if (currentCity) {
      currentCity.points.splice(index, 1);

      if (currentCity.points.length === 0) {
        const currentIndex = checkout.value?.checkoutFias.findIndex(item => item.city === city.city);
        if (currentIndex)
          checkout.value?.checkoutFias.splice(currentIndex, 1)
      }
    }
  }

  const initPvzStore = (initialStore: PickPointsRootDto) => {
    checkout.value = initialStore
  }

  const citiesName = computed(() => {
    return checkout.value?.checkoutFias.flatMap((city) => ({ value: city.city }))
  })

  return {
    checkout,
    initPvzStore,
    addPvz,
    editPvz,
    deletePvz,
    citiesName
  }
})
