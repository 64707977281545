import { RoutingCenterDto, ReceivedDistributionDto } from "@/models/api/routing-center-dto.interface";
import axios, { AxiosError } from 'axios';
import { API_URL } from '@/utils/constants';
import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';


export const getRoutingCenter = async (
  params: PaginationParams,
): Promise<Pageable<RoutingCenterDto>> => {
  const response = await axios
    .get(API_URL + 'routing-center', { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};

export const getPhoneNumber = async (orderId: string): Promise<any> => {
  const response = await axios
    .get(API_URL + 'routing-center/phone', { params: { orderId: orderId } })
    .then((response) => response);
  return response.data
};

export const changePP = async (orderId: string, pointId: number | null): Promise<any> => {
  const response = await axios
    .put(API_URL + `routing-center/update-order-point/${orderId}`, null, { params: { pointId: pointId } })
    .then((response) => response);
  return response.data
};

export const getDistributionReceived = async (
  params: PaginationParams,
): Promise<Pageable<ReceivedDistributionDto>> => {
  const response = await axios
    .get(API_URL + 'distribution-center/received', { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};

export const pickupDistributionOrder = async (orderId : string, performCheckFlag:boolean = true): Promise<false | 'valid' | 'invalid' | string> => {

  return axios.post(API_URL + 'distribution-center/accept-transit', null, {
    params: {
      orderId,
      performCheckFlag
    }
  }).then(() => 'valid')
    .catch((err: AxiosError)=>{
    if(err?.response?.status === 418) {
      return 'invalid'
    }
    return false
  }
  )
};
