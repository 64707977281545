import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _mergeProps({
    class: "ui-icon",
    xmlns: "http://www.w3.org/2000/svg"
  }, _ctx.iconSize), [
    _createElementVNode("use", { "xlink:href": _ctx.iconPath }, null, 8, _hoisted_1)
  ], 16))
}