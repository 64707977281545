<template>
  <header class="ui-header">
    <div class="ui-header__left">
      <button
        v-if="withBackButton"
        class="ui-header__back"
        @click="handleGoBack"
      >
        <el-icon size="24">
          <arrow-left-bold />
        </el-icon>
      </button>
      <h1 class="ui-header__title">
        <slot />
      </h1>
    </div>
    <div class="ui-header__right">
      <slot name="right" />
    </div>
  </header>
</template>

<script lang="ts">
import { ArrowLeftBold } from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'ui-header',
  components: {
    ArrowLeftBold,
  },
  props: {
    withBackButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const handleGoBack = () => router.back();

    return {
      handleGoBack,
    };
  },
});
</script>

<style lang="stylus" scoped>
.ui-header
  margin-bottom 32px
  display flex
  align-items center
  justify-content space-between

  &__left
    display flex
    align-items center
    justify-content space-between

  &__title
    h3()
    color var(--el-color-primary-dark)

  &__back
    margin-right 24px
    width 40px
    height 40px
    display flex
    align-items center
    justify-content center
    background-color var(--color-white)
    border-radius var(--el-border-radius-base)

  &__right
    h6()

</style>
