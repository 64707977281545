import axios, { AxiosError } from 'axios';
import { API_URL } from '@/utils/constants';
import { Pageable } from '@/models/pageable.type';
import { PaginationParams } from '@/models/pagination-params.type';
import { SortingCenterDto } from "@/models/api/sorting-center-dto.inreface";


export const getSortingCenter = async (
  params: PaginationParams,
): Promise<Pageable<SortingCenterDto>> => {
  const response = await axios
    .get(API_URL + 'sorting-center', { params })
    .then((response) => ({
      data: {
        data: response.data.content,
        total: response.data.totalElements,
        totalPages: response.data.totalPages
      },
    }));

  return response.data;
};


export const updateOrderStatusFromSorting = async (orderId: string) =>{
   const response = await axios.post(API_URL + 'sorting-center/updateStatus', null, {params:{orderId}}).then(response => response)
   return response.data
}
